import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { CanLoad, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { UserService } from 'src/app/authentication/user.service'
import { AdminUser } from '../models/admin-model';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';



@Injectable({
    providedIn: 'root'
})
export class IsUserService implements CanActivate {

    private user: AdminUser = {
        name: '',
        adminUser: ''
    };


    // @ViewChild('e2lang',{static:false}) e2DivElementRef: ElementRef;

    
    constructor(private router: Router,
        private userService: UserService,
        private _authservice: AuthService,
        private toastr: ToastrService) {
    }


    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
        this.user = this.userService.getUser();       
        
        if (this.user && this.user.name && this.user.adminUser) {           
            if (this.user.adminUser.toUpperCase() == 'USER' || this.user.adminUser.toUpperCase() == 'ADMIN') { 
                localStorage.removeItem('state_url');
                localStorage.setItem('state_url', state.url);                            
                return true;
            }
            else {
              this.toastr.error('Du är inte auktoriserad');
                //this.toastr.error(this.e2DivElementRef.nativeElement.innerHTML, '', {enableHtml: false})
                
                localStorage.clear();
                this._authservice.userAuthenticationAPI();
            }
        } else {
            this.toastr.error('Du är inte auktoriserad');
           // this.toastr.error(this.e2DivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
            localStorage.clear();
            this._authservice.userAuthenticationAPI();
        }
    }
}