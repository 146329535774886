<div class="panel panel-primary">
    <div class="panel-body">


        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke.jpg" class="img-rounded" alt="Cinque Terre" width="250" height="250"
                style="margin-top: 20px;">
        </div> -->

        <form (keydown.enter)="$event.preventDefault()" class="form-horizontal col-lg-7" role="form" id="form"
            #loopRegistrationForm="ngForm" submit>

            <div class="form-group">
                <label for="Klass"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Klass (A =1 , CE =2)' | translate}}</label>
                <div class="col-lg-2">
                    <!-- <input type="text" class="form-control" id="classType" required ngModel name="classType"
                        pattern="[0-9]" maxlength="1" #classType="ngModel" /> -->
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="classType" ngModel name="classType"
                            #classType=ngModel [(ngModel)]="selectedValue" (keyup.enter)="focusOnType(classType)"
                            style="width: 250px; height: 120px; font-size: 72pt;">
                            <option [value]="1">A</option>
                            <option [value]="2">CE</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="classType.touched && !classType.valid">
                        <div *ngIf="classType.errors.required">{{ 'Du har inte fyllt i Klass !' | translate}}</div>
                        <div *ngIf="classType.errors.pattern">{{ 'Endast nummer !' | translate}}</div>
                    </div>
                </div>

            </div>

            <div class="form-group">
                <label for="oktype" class="col-lg-offset-4 col-lg-4 control-label">{{'Ok typ'|translate}}</label>
                <div class="col-lg-2">
                    <input required id="type" ngModel name="type" type="text" pattern="[0-9]*" #type="ngModel" autoFocus
                        class="form-control" placeholder="" (keyup.enter)="focusOnLoopNumber(type)">
                    <div class="alert alert-danger" *ngIf="type.touched && !type.valid">
                        <div *ngIf="type.errors.required">{{ 'Du har inte fyllt i Typ !' | translate}}</div>
                        <div *ngIf="type.errors.pattern">{{ 'Endast nummer !' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{ 'Löpnummer' | translate}}</label>
                <div class="col-lg-2">
                    <input required id="yokeNumber" ngModel name="yokeNumber" type="text" pattern="[0-9]*"
                        #yokeNumber="ngModel" class="form-control" placeholder=""
                        (keyup.enter)="focusOnSubmit(classType, type, yokeNumber)">
                    <div class="alert alert-danger" *ngIf="yokeNumber.touched && !yokeNumber.valid">
                        <div *ngIf="yokeNumber.errors.required">{{'Du har inte fyllt i löpnummer !' | translate}}</div>
                        <div *ngIf="yokeNumber.errors.pattern">{{ 'Endast nummer !' | translate}}</div>
                    </div>
                </div>
            </div>

            <h1>
                <label
                    style="font-size: 13px; margin-left: 40%; color: #033a6a;">{{ '*Skriv in klass, typ och löpnummer på oket.' | translate}}</label>
            </h1>

            <div class="form-group">
                <div class="col-lg-offset-5 col-lg-3">
                    <button class="btn btn-success"
                        (click)="submit(loopRegistrationForm.value); resetFields(loopRegistrationForm)" type="submit"
                        [class.disabled]="!loopRegistrationForm.valid"
                        (keyup.enter)="submit(loopRegistrationForm.value); resetFields(loopRegistrationForm)"
                        id="submitBtn">{{ 'Registrera' | translate}}</button>

                </div>
                <div class="col-lg-2">
                    <button class="btn btn-success" type="button" style="background-color: #033a6a;
                        border-color: #033a6a; margin-left: 0px;"
                        (click)="resetFields(loopRegistrationForm)" (keyup.enter)= "resetFields(loopRegistrationForm)">{{'Återställa' | translate}}</button>

                </div>
            </div>
        </form>

        <div class="form-group col-lg-4">
            <br><br>
            <label for="totalt" class="col-lg-offset-5 col-lg-2 control-label"
                style="width: 115px; ">{{'Totalt' |translate}}</label>
            <div class="col-lg-2">
                <input type="text" [(ngModel)]="txtlooptot" class="form-control" id="totalt" placeholder=""
                    style="width: 80px; " disabled>
            </div>
            <label for="loop" class="col-lg-offset-5 col-lg-2 control-label"
                style="width: 115px;">{{ '20 slinga' | translate}}</label>
            <div class="col-lg-2">
                <input type="text" [(ngModel)]="txtloop20" class="form-control" id="loop" placeholder=""
                    style="width: 80px; " disabled>
            </div>
        </div>

        <span [hidden]="true"
            #getregistrationerror>{{'Ok individ finns inte registrerad, OK typ ej aktiv / eller fel inmatat!' | translate}}</span>
        <span [hidden]="true"
            #getregistrationerror>{{'Ok individ finns inte registrerad, OK typ ej aktiv / eller fel inmatat!' | translate}}</span>
        <span [hidden]="true"
            #paintedcheckerror>{{'Ok individ är ej avfärgningsregistrerad och satt i Produktion' | translate}}</span>
        <span [hidden]="true"
            #reachedscraperror>{{'Ok individ har uppnått skrotningsgräns, SKA skrotningsRegistreras och skrotas' | translate}}</span>
        <span [hidden]="true" #scrapinvid>{{'Ok individ är skrotad!' | translate}}</span>
        <span [hidden]="true" #invidmissing>{{'Ok individ saknas i register!' | translate}}</span>
        <span [hidden]="true"
            #yokeinspectionadded>{{'har status Avfärgas och ska skickas för avfärgning, Totalt antal loopar' | translate}}</span>
        <span [hidden]="true" #class>{{'Klass' | translate}}</span>
        <span [hidden]="true" #yoketype>{{'Ok typ' | translate}}</span>
        <span [hidden]="true" #loopnumber>{{'Löpnummer' | translate}}</span>
        <span [hidden]="true" #rtal>{{'registrerad. Totalt antal loopar' | translate}}</span>

    </div>
</div>