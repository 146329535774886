import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { YokeSkrot } from 'src/app/models/admin-model';
import { ShowScrapService } from './show-scrap.service';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-show-scrap',
  templateUrl: './show-scrap.component.html',
  styleUrls: ['./show-scrap.component.css'],
  providers: [ShowScrapService]
})
export class ShowScrapComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  isDtInitialized: boolean = false;

  dtOptions: DataTables.Settings = {};
  yokeSkrot: YokeSkrot[] = [];
  scrap: YokeSkrot;
  dtTrigger: Subject<any> = new Subject<any>();
  newScrap: any = {};

   @ViewChild('slang',{static:false}) sDivElementRef: ElementRef;
  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;
  
  constructor(private showScrapService: ShowScrapService, private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5
    };

    this.getYokeSkrotList();
  }


  AddNewScrapForm(scrapModal) {

    this.modalService.open(scrapModal, { centered: true, windowClass: 'window-modal' });
    this.newScrap = {
      skrotId: null,
      skroterror: null
    }
  }
  EditScrapForm(editScrapModal, scrap) {

    this.modalService.open(editScrapModal, { centered: true, windowClass: 'window-modal' });
    this.newScrap = {
      skrotId: scrap.skrotId,
      skroterror: scrap.skroterror
    }
  }

  getYokeSkrotList() {
    this.showScrapService.getYokeSkrotList()
      .subscribe((result) => {
        this.yokeSkrot = result;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        }
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
         // console.log(this.yokeSkrot);
        }
      )
  }

  addScrap(form) {
    this.showScrapService.addOrUpdateYokeSkrot(form)
    .subscribe(resp => {
      console.log(resp);
      this.toastr.success(this.sDivElementRef.nativeElement.innerHTML, '', {enableHtml: false}); 
     // this.toastr.success('Yoke Scrap Added/Updated Successfully', '');
      this.ngOnInit();
    }, error => {
      //this.toastr.error('Yoke Scrap Already Exists', 'Error');
      this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
    } );
    this.modalService.dismissAll();

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
