<div class="panel panel-primary">
    <div class="panel-heading">
        <h4 *ngIf = "okType" class="panel-title" >{{'Ok typ' | translate}}</h4>
        <h4 *ngIf = "Supplier" class="panel-title" >{{'Leverantör' | translate}}</h4>
        <h4 *ngIf = "user" class="panel-title" >{{'Användare' | translate}}</h4>
        <h4 *ngIf = "Justertyp" class="panel-title" >{{'Justertyp' | translate}}</h4>
        <h4 *ngIf = "Skrotorsak" class="panel-title" >{{'Skrotorsak' | translate}}</h4>
        <h4 *ngIf = "show"class="panel-title" >{{'Sökväg instruktion' | translate}}</h4>
       
      

    </div>
    <div class="panel-body">
        <form id="form" action="" method="get">
            <div class="row">
                <div class="text-center">
                    <div class="form-group">
                        <button class="btn btn-default" type="reset" on-click="showOktype()">{{ 'Ok typ' | translate}}</button>
                        <button class="btn btn-default" type="reset" on-click="showsupplier()">{{'Leverantör' | translate}}</button>
                        <button class="btn btn-default" type="reset" on-click="showuser()">{{'Användare' | translate}}</button>
                        <button class="btn btn-default" type="reset" on-click="showadjustment()">{{'Justertyp' | translate}}</button>
                        <button class="btn btn-default" type="reset" on-click="showscrap()">{{'Skrotorsak' | translate}}</button>
                        <button class="btn btn-default" type="reset" on-click="showinstruction()">{{'Sökväg instruktion' | translate}}</button>
                    </div>
                </div>
            </div>
        </form>
        <router-outlet></router-outlet>

    </div>
</div>