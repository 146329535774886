import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { EventInformation, ReportEvent } from 'src/app/models/reports-model';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { EventInformationService } from './event-information.service';
import { DecolorizationService } from 'src/app/register/register-decolorization/register-decolorization.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-event-information',
  templateUrl: './event-information.component.html',
  styleUrls: ['./event-information.component.css'],
  providers: [DatePipe, EventInformationService]
})
export class EventInformationComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;
  
  constructor(private eventInformationService: EventInformationService, private decolorizationService: DecolorizationService, private datePipe: DatePipe, private toastr: ToastrService) { }
  dtOptions: any = {};
  eventInfo: EventInformation[] = [];
  reportEvent: ReportEvent;
  dtTrigger: Subject<any> = new Subject<any>();
  listTypes: any;
  selectedValue="2";

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'print',
        'excel',
        'csv'
      ]
    };
    this.resetForm();
    this.getYokeTypesList();
  }


  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.reportEvent = {
      class: null,
      type: null,
      yokeNumber: null

    }
  }

  getReportEventInfo(form) {
    this.reportEvent.class = form.class;
    this.reportEvent.type = form.type;
    this.reportEvent.yokeNumber = form.yokeNumber;


    this.eventInformationService.getEventInfo(this.reportEvent.class, this.reportEvent.type, this.reportEvent.yokeNumber)
      .subscribe((result) => {
        this.eventInfo = [];
        this.eventInfo = result;
        this.rerender();
      },
        error => { //This is error part
          console.log(error.message);
          this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
          //this.toastr.error('poster hittades inte', 'Error');
        },
        () => {
          console.log(this.eventInfo.length);
          if(this.eventInfo.length === 0)
          this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
          //this.toastr.error('poster hittades inte', 'Error');

        }
      )
  }

  getYokeTypesList() {
    this.decolorizationService.getYokeTypes()
      .subscribe((result) => {
        this.listTypes = result;
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
         // console.log(this.listTypes);
        }
      )
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
