<span [hidden]="true" #elang>{{'poster hittades inte' | translate}}</span>

<div class="panel panel-primary">
    <div class="panel-body">

        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke1.jpg" class="img-rounded" alt="Cinque Terre" width="250" height="250"
                style="margin-top: 20px;" height="236">
        </div> -->
        <form class="form-horizontal col-lg-7" #showTotalInfoForm="ngForm"
            (ngSubmit)="showTotalInfoSubmit(showTotalInfoForm.value)" role="form">
            <div class="form-group">
                <label for="oktype" class="col-lg-offset-4 col-lg-4 control-label">{{'Ok typ' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="type" name="type" ngModel
                            #type="ngModel">
                            <option [value]=0>All</option>
                            <option *ngFor="let type of listTypes" [value]="type">{{type}}</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="type.touched && !type.valid">
                        <div *ngIf="type.errors.required">{{'Du måste välja oktyp' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-offset-8 col-lg-3">
                    <button class="btn btn-success" type="submit" [class.disabled]="!showTotalInfoForm.valid"
                        id="hämtadatabtn5">{{'Hämta data' | translate}}</button>
                </div>
            </div>



        </form>
        <div>
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th class="text-center">{{'Typ' | translate}}</th>
                        <th class="text-center">{{'LöpNr' | translate}}</th>
                        <th class="text-center">{{'Antal OK' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let totalinfo of totalinfolist">
                        <td>{{ totalinfo.typ }}</td>
                        <td>{{ totalinfo.loopnr }}</td>
                        <td>{{ totalinfo.antal_ok }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>