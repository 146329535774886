<body class="bootstrap">

    <div class="container-fluid" style="min-height:100%">

        <div class="row " id="prototype-header">
            <div class="col-md-6">
                <a class="txt-logo desktop-only" href="/">
                    <br>
                    <img class="img-fluid"
                        src="https://static.scania.com/resources/logotype/scania/scania-wordmark.svg" />
                    <span class="logo-divider"></span>
                    <h1 style="display: inline;"><span class="first-element">OKRA</span></h1>
                </a>
                <a href="" class="shield-logo mobile-only"></a>
            </div>

            <div class="col-md-6">
                <ul class="navbar-right header-links hidden-print hidden-xs">
                    <li>
                        <button (click)="isPopUserOpen(popUser)">
                            <i class="icon-user icon-large margin-right"></i>
                            {{currentUser}}
                        </button>
                        <span class="label label-default" id="LoggedInUser"></span>
                    </li>
                    <li>
                        <h6 style="display: inline; padding-right: 5px;">EN</h6> <a class="sixth-element"
                            (click)="switchLang('en')"> <img id='icon' src="../../assets/icon/en.png" /></a>

                    </li>
                    <li>
                        <h6 style="display: inline; padding-right: 5px;">SV</h6><a class="seventh-element"
                            (click)="switchLang('sv')"> <img id='icon' src="../../assets/icon/sv.png" /></a>
                    </li>

                    <div class="right-notification">
                        <ng-template #popUser>
                            <div id="modal3" class="modal-header">
                                <div style="margin: 1rem">
                                    <b>Name : {{user.name}}</b><br />
                                    <b>Global Id : {{user.userId}}</b><br />
                                    Email : {{user.emailId}}<br />
                                    <b>Access : {{user.adminUser}}</b>
                                    <br />
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </ul>
            </div>
        </div>


        <nav class="navbar navbar-expand-md navbar-light ">

            <button class="navbar-toggler visible-xs" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav">
                    <li class="nav-item userText" routerLinkActive="active">
                        <a routerLink="dashboard" class="nav-link">
                            <mat-icon>dashboard</mat-icon> {{ 'instrumentbräda' | translate}}
                        </a>
                    </li>

                    <li class="second-element nav-item" routerLinkActive="active">

                        <a routerLink="register" class="nav-link"> <i class="fas fa-edit" style="font-size:25px"></i>
                            {{ 'REGISTRERA' | translate}}</a>
                    </li>

                    <li class="third-element nav-item" routerLinkActive="active">

                        <a routerLink="reports" class="nav-link"> <i class="icon-file-text" style="font-size:25px"></i>
                            {{ 'RAPPORTER' | translate}}</a>
                    </li>

                    <li class="forth-element nav-item" routerLinkActive="active">

                        <a routerLink="administration" class="nav-link"> <i class="icon-cogs"
                                style="font-size:25px"></i> {{ 'ADMINISTRERING' | translate}}</a>
                    </li>

                    <li class="fifth-element nav-item" routerLinkActive="active">

                        <a routerLink="instruction" class="nav-link">
                            <mat-icon>book_open</mat-icon> {{ 'INSTRUKTION MANUELL' | translate}}
                        </a>
                    </li>
                </ul>
                <div class="pull-right">
                    <button class="btn btn-success" (click)="Intro()" type="submit"><i class="icon-user"></i>
                        {{ 'Hjälp mig' | translate}}</button>
                </div>
            </div>
        </nav>
        <span [hidden]="true" #welcomeokra>{{'Välkommen till OKRA' | translate}}</span>
        <span [hidden]="true" #welcomeokratext>{{'OKRA Application hanterar de processer som ett ok genomgår från skapandet till slutförsamlingen. Denna funktion hjälper dig med de viktigaste alternativen som finns tillgängliga i OKRA.' | translate}}</span>

        <span [hidden]="true" #register>{{'ETTA ÄR REGISTRERINGSavsnitt' | translate}}</span>
        <span [hidden]="true" #registertext>{{'Registreringsavsnittet gör det möjligt att registrera olika registreringar som: Loopregistrering, Registerjustering, Registrera avfärgning, Registrera skrotning, Registrera nytt åk.' | translate}}</span>

        <span [hidden]="true" #report>{{'Detta är rapportavsnitt' | translate}}</span>
        <span [hidden]="true" #reporttext>{{'Rapportsektionen gör det möjligt att extrahera olika rapporter som: Loop Information, Evet Information, Scrap Information, Sum Information, Total Information.' | translate}}</span>

        <span [hidden]="true" #admin>{{'DETTA ÄR ADMINSEKTION' | translate}}</span>
        <span [hidden]="true" #admintext>{{'Administrationsavsnitt tillåter applikationsadministratörer att konfigurera mastervärden för: ok-typ, justering, användare, skrotning, leverantör, bruksanvisning.' | translate}}</span>

        <span [hidden]="true" #instructionmanual>{{'ANVÄNDNINGSHANDBOK' | translate}}</span>
        <span [hidden]="true" #instructionmanualtext>{{'På denna sida kan du se och ladda ner den senaste bruksanvisningen för okra.' | translate}}</span>

        <span [hidden]="true" #language>{{'SPRÅKPREFERENS' | translate}}</span>
        <span [hidden]="true" #languagetext>{{'Alternativet på engelska gör det möjligt att visa hela OKRA-applikationen på engelska.' | translate}}</span>

        <span [hidden]="true" #swlanguagetext>{{'Det svenska språkalternativet gör det möjligt att visa hela OKRA-applikationen på svenska' | translate}}</span>
        <router-outlet></router-outlet>
    </div>

    <c-corporate-footer></c-corporate-footer>
</body>