<span [hidden]="true" #slang>{{'Ok Typ Tillagt/Uppdaterad Lyckades' | translate}}</span>
        
        <span [hidden]="true" #elang>{{'Ok Typ Redan Finns' | translate}}</span>


<div class="container">
  <div id="wrapper">
    <button class="btn btn-success text-btn" (click)="AddOkTypeForm(OkTypeModal)">
      <i class="fa fa-plus"></i>{{ 'Lägg till Ok typ'| translate }}</button>
  </div>
  <br>
  <br>
  <div class="col-md-12">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-bordered table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center" name="type"> {{'Ok typ' | translate}}</th>
          <th class="text-center" name="interval">{{'Avfärgnings gräns' | translate}}</th>
          <th class="text-center" name="totalCount">{{'Skrotnings gräns' | translate}}</th>
          <th class="text-center" name="description">{{'VR-nummer' | translate}}</th>
          <th class="text-center" name="changeDate">{{'ändrat datum' | translate}}</th>
          <th class="text-center" name="regDate">{{'registrerat datum' | translate}}</th>
          <th class="text-center" name="aktiv">{{'Aktiv' | translate}}</th>
          <th class="text-center" name="edit">{{'Redigera' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let okType of yokeTypes">
          <td>{{okType.type}}</td>
          <td>{{okType.interval}}</td>
          <td>{{okType.totalCount}}</td>
          <td>{{okType.description}}</td>
          <td>{{okType.changedate}}</td>
          <td>{{okType.regdate}}</td>
          <td>{{okType.aktiv}}</td>
          <td class="text-right">
            <button class="btn btn-info mr-2" (click)="EditOkTypeForm(OkTypeModal, okType)">
              <i class="fa fa-edit"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #OkTypeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Redigera Ok Typ' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">x</span>
    </button>
  </div>


  <form class="form-horizontal" #addYokeTypeForm="ngForm" (ngSubmit)="addYokeType(addYokeTypeForm.value)" role="form">
    <div class="modal-body">

      <div class="container">

        <div class="form-group">

          <input type="hidden" name="id" ngModel #id="ngModel" [(ngModel)]="newYokeType.id">

          <label for="type" class="col-sm-5 ">{{'Ok typ' | translate}}</label>
          <div col-md-1>
            <input type="text" name="type" ngModel #type="ngModel" [(ngModel)]="newYokeType.type" pattern="[0-9]*"
              required />
            <div class="alert alert-danger" *ngIf="type.touched && !type.valid">
              <div *ngIf="type.errors.required">{{'Inmatningsfältet får inte vara tomt.' | translate}}</div>
              <div *ngIf="type.errors.pattern">{{ 'Endast nummer !' | translate}}</div>
            </div>
          </div>

          <br />

          <label for="interval" class="col-sm-5 ">{{'Avfärgnings gräns' | translate}}</label>
          <div col-md-1>
            <input type="text" name="interval" ngModel #interval="ngModel" [(ngModel)]="newYokeType.interval"
              pattern="[0-9]*" required />
            <div class="alert alert-danger" *ngIf="interval.touched && !interval.valid">
              <div *ngIf="interval.errors.required">{{'Inmatningsfältet får inte vara tomt.' | translate}}</div>
              <div *ngIf="interval.errors.pattern">{{ 'Endast nummer !' | translate}}</div>

            </div>
          </div>
          <br />

          <label for="totalCount" class="col-sm-5 ">{{'Skrotnings gräns' | translate}}</label>
          <div col-md-1>
            <input type="text" name="totalCount" ngModel #totalCount="ngModel" [(ngModel)]="newYokeType.totalCount"
              pattern="[0-9]*" required />
            <div class="alert alert-danger" *ngIf="totalCount.touched && !totalCount.valid">
              <div *ngIf="totalCount.errors.required">{{'Inmatningsfältet får inte vara tomt.' | translate}}</div>
              <div *ngIf="totalCount.errors.pattern">{{ 'Endast nummer !' | translate}}</div>

            </div>
          </div>

          <br />

          <label for="description" class="col-sm-5 ">{{'VR-nummer' | translate}}</label>
          <div col-md-1>
            <input type="text" name="description" ngModel #description="ngModel" [(ngModel)]="newYokeType.description"
              pattern="[0-9]*" required />
            <div class="alert alert-danger" *ngIf="description.touched && !description.valid">
              <div *ngIf="description.errors.required">{{'Inmatningsfältet får inte vara tomt.' | translate}}</div>
              <div *ngIf="description.errors.pattern">{{ 'Endast nummer !' | translate}}</div>

            </div>
          </div>
          <br />


          <label for="aktiv" class="col-sm-5 ">{{'Aktiv' | translate}}</label>
          <div col-md-1>
            <select type="text" name="aktiv" ngModel #aktiv="ngModel" [(ngModel)]="newYokeType.aktiv" required
              style="width: 182px;height:28px;">
              <option [value]="true">{{'Sant' | translate}}</option>
              <option [value]="false">{{'Falska' | translate}}</option>
            </select>
            <div class="alert alert-danger" *ngIf="aktiv.touched && !aktiv.valid">
              <div *ngIf="aktiv.errors.required">{{'Inmatningsfältet får inte vara tomt.' | translate}}</div>
            </div>
          </div>

        </div>
      </div>

    </div>



    <div class="modal-footer">
      <button class="btn btn-success" type="submit" [class.disabled]="!addYokeTypeForm.valid"
        id="hämtadatabtn4">{{'Spara' | translate}}</button>
      <button type="button" class="btn btn-primary" style="background-color: #033a6a;
      border-color: #033a6a;" (click)="modal.close('Save click')">{{'Avbryt' | translate}}</button>
    </div>

  </form>
</ng-template>