import { Injectable } from '@angular/core'; import { HttpClient, HttpResponse } from '@angular/common/http';
import { Response } from '@angular/http';
import { AppConfig } from 'src/app/config/app.config';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from "rxjs"
import { map, catchError } from 'rxjs/operators';
import { Supplier } from 'src/app/models/admin-model';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ShowSupplierService {

  constructor(private config: AppConfig, private http: HttpClient) { }
  private apiUrl: string = this.config.get('apiUrl') + 'Admin';

  suppliers: Supplier[];
  getSupplierTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetSupplierTypes`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  AddOrUpdateSupplierType(suppliers: Supplier): Observable<any> {
    return this.http.post(`${this.apiUrl}/AddOrUpdateSupplierType`, suppliers, httpOptions);

  }

  errorHandler(error: Response) {
    // console.log(error);  
    return throwError(error);
  }


}
