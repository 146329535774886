import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoopRegistrationBE, YokeInspection } from 'src/app/models/loop-registration-model';
import { NgForm } from '@angular/forms';
import { RegisterAdjustmentService } from './register-adjustment.service';
import { DecolorizationService } from '../register-decolorization/register-decolorization.service';
import { ToastrService } from 'ngx-toastr';
import { Justertyp } from 'src/app/models/utils';
import { IfStmt } from '@angular/compiler';
import { stringify } from 'querystring';
import { format } from 'url';
import { DatePipe, formatDate } from '@angular/common';


@Component({
  selector: 'app-register-adjustment-action',
  templateUrl: './register-adjustment.component.html',
  styleUrls: ['./register-adjustment.component.css'],
  providers: [RegisterAdjustmentService, ToastrService]
})
export class RegisterAdjustmentComponent implements OnInit {

  @ViewChild('getadjustmenterror',{static:false}) getAdjustmentErrorRef: ElementRef;
  @ViewChild('incorrectinvid',{static:false}) inCorrectInvidRef: ElementRef;
  @ViewChild('addinspectionerror',{static:false}) addInspectionErrorRef: ElementRef;
  @ViewChild('updateregistererror',{static:false}) updateRegisterErrorRef: ElementRef;
  @ViewChild('adjusttype',{static:false}) adjustTypeRef: ElementRef;
  @ViewChild('regclass',{static:false}) regClassRef: ElementRef;
  @ViewChild('yoketype',{static:false}) yokeTypeRef: ElementRef;
  @ViewChild('loopnumber',{static:false}) loopNumberRef: ElementRef;
  @ViewChild('okinvidscrap',{static:false}) okInvidScrapRef: ElementRef;
  @ViewChild('alos',{static:false}) alosRef: ElementRef;


  constructor(private registerAdjustmentService: RegisterAdjustmentService,
    private decolorizationService: DecolorizationService,
    private toastr: ToastrService,
    // private datePipe: DatePipe
  ) { }
  smartphone: any = [];

  selectedValue="2";

  registration: LoopRegistrationBE;
  yokeinspection: YokeInspection = undefined;
  check: boolean;
  lblmsgn: string;
  Klass: string;
  jsttype: number;
  currentDate: Date;
  date: any;
  listJustertyp: any;
  listTypes: any;

  ngOnInit() {
    this.resetForm();
    this.getAdjustmentType();
    this.getYokeTypesList();
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();

    this.registration = {
      classtype: null,
      type: null,
      yokeNumber: null,
      totalCount: null,
      totalCounter: null,
      skrotad: null,
      interval: null,
      painted: null,
      inspectionCounter: null,
      intStatus: null,
      counterDate: null,
      skrotDatum: null,
      aktiv: null
    }

  }

  getAdjustmentType() {

    this.registerAdjustmentService.getAjustmentType()
      .subscribe(response => {
        this.listJustertyp = response.body;

      }, error => {
      //  this.lblmsgn = 'Error in getting Adjustment type'
      //  this.toastr.error(this.lblmsgn, 'Error');
        this.toastr.error(this.getAdjustmentErrorRef.nativeElement.innerHTML, 'Error', {enableHtml: false});
        return false;
      }
      )
  }

  getYokeTypesList() {
    this.decolorizationService.getYokeTypes()
      .subscribe((result) => {
        this.listTypes = result;
      },
        error => { //This is error part
         // console.log(error.message);         
        },
        () => {
         // console.log(this.listTypes);
        }
      )
  }
  correctiveSubmit(form) {
   // console.log(form);
    //debugger;


    this.registration.classtype = parseInt(form.class);
    this.registration.type = parseInt(form.type); // oktyp
    this.registration.yokeNumber = parseInt(form.yokeNumber);
    this.jsttype = parseInt(form.justertyp);

    this.currentDate = new Date();
    this.date = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');

    if (this.jsttype === 5)
      this.registration.intStatus = 7;
    else
      this.registration.intStatus = 3;
    regdatum: Date();

    this.check = false;


    const promise = new Promise((resolve, reject) => {
      this.registerAdjustmentService.getRegistrationList(this.registration.classtype, this.registration.type, this.registration.yokeNumber)
        .toPromise()
        .then((response: any) => {
          this.registerAdjustmentResponse(response.body)
          resolve();
        },
          err => {
           // this.lblmsgn = 'Ok individ är ej Registrerad eller fel inmatat'
          //  this.toastr.error(this.lblmsgn, 'Error');
          this.toastr.error(this.inCorrectInvidRef.nativeElement.innerHTML, 'Error', {enableHtml: false});
            return false;
            reject(err);
          }
        );
    });
    return promise;



  }

  registerAdjustmentResponse(regList: LoopRegistrationBE) {
    if (regList != null) {
      if (this.registration.yokeNumber != regList.yokeNumber) {
        this.check = true;

        this.lagrat(); // om vilkoret inte är uppfyllt enligt ovan close myConnection och visa felmedelande.
      }
      else {

        // check1 = True
        this.registration.inspectionCounter = regList.inspectionCounter;
        this.registration.totalCounter = regList.totalCounter;
        this.registration.interval = regList.interval;
        this.registration.totalCount = regList.totalCount;
        this.registration.skrotad = regList.skrotad;
        this.registration.painted = regList.painted;
        this.registration.skrotDatum = regList.counterDate;

        this.lagra(); // samla variabler och utför nästa sqlsats
      }
    }
  }
  lagrat() {
   // this.lblmsgn = "Ok individ är ej Registrerad eller fel inmatat";
   // this.toastr.error(this.lblmsgn, 'Error');
   this.toastr.error(this.inCorrectInvidRef.nativeElement.innerHTML, 'Error', {enableHtml: false});
    return false;
  }

  lagra() {
    if (this.registration.skrotad === 4)
      this.skrot();
    if (this.registration.inspectionCounter < this.registration.interval && this.registration.totalCounter < this.registration.totalCount) {
      this.registration.inspectionCounter = this.registration.inspectionCounter + 0;
      this.registration.totalCounter = this.registration.totalCounter + 0;
      this.date = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');
      this.yokeinspection = {
        id: this.registration.painted,
        class: this.registration.classtype,
        type: this.registration.type,
        yokeNumber: this.registration.yokeNumber,
        status: this.registration.intStatus,
        errorTypeId: this.jsttype,
        yokeComment: this.jsttype,
        inspectDate: this.date,
        inspectionCounter: this.registration.inspectionCounter,
        totalcounter: this.registration.totalCounter
      };

      const promise = new Promise((resolve, reject) => {
        this.registerAdjustmentService.addYokeInspection(this.yokeinspection)
          .toPromise()
          .then((response: any) => {
            this.showInformation();
            resolve();
          },
            err => {
             // this.lblmsgn = "error in adding Yoke Inspection";
             // this.toastr.error(this.lblmsgn, 'Error');
             this.toastr.error(this.addInspectionErrorRef.nativeElement.innerHTML, 'Error', {enableHtml: false});
              return false;
              reject(err);
            }
          );
      });
      return promise;
    }
    else if (this.registration.totalCounter >= this.registration.totalCount) {
      this.skrot();

    }
  }

  showInformation() {
    if (this.registration.classtype == 2)
      this.Klass = "CE";
    if (this.registration.classtype == 1)
      this.Klass = "A";
    let selectedCategory
    this.listJustertyp.forEach(element => {
      if (element.errorTypeId == this.jsttype) {
        selectedCategory = element.errorType;
      }
    });
    this.lblmsgn = this.adjustTypeRef.nativeElement.innerHTML + " " + selectedCategory + " " + this.regClassRef.nativeElement.innerHTML + " " + this.Klass + " " + this.yokeTypeRef.nativeElement.innerHTML + " " + this.registration.type + " " + this.loopNumberRef.nativeElement.innerHTML + " " + this.registration.yokeNumber;
    this.toastr.success(this.lblmsgn, 'Success', {enableHtml: false});
    return false;
  }

  skrot() {
    this.registration.inspectionCounter = this.registration.inspectionCounter + 1


    const promise = new Promise((resolve, reject) => {
      this.registerAdjustmentService
        .updateYokeRegister(this.registration.inspectionCounter, this.registration.classtype, this.registration.type, this.registration.yokeNumber)
        .toPromise()
        .then((response: any) => {
          this.showUpdatedInformation();
          resolve();
        },
          err => {
            //this.lblmsgn = "error in Updating yoke register";
           // this.toastr.error(this.lblmsgn, 'Error');
           this.toastr.error(this.updateRegisterErrorRef.nativeElement.innerHTML, 'Error', {enableHtml: false});
            return false;
            reject(err);
          }
        );
    });
    return promise;

    //TODO: update Tblyokeregister(class, type, yokeNumber)

    /*  if (this.registration.inspectionCounter == 99) {
        this.lblmsgn = "Ok  individ skrotad " + this.registration.skrotDatum;
        this.toastr.error(this.lblmsgn, 'Error');
        this.showBtn = false;
        return;
      }
  
      else {
        let val = this.registration.inspectionCounter - 99;
        this.lblmsgn = "Ok  individ skrotad " + this.registration.skrotDatum + " antal loopar över skrotgräns =  " + val + " ";
        this.toastr.error(this.lblmsgn, 'Error');
        this.showBtn = false;
        return;
      }*/
  }

  showUpdatedInformation() {
    if (this.registration.inspectionCounter == 99) {
      this.lblmsgn = this.okInvidScrapRef.nativeElement.innerHTML + " " + this.registration.skrotDatum;
      this.toastr.error(this.lblmsgn, 'Error',{enableHtml: false});
      return false;
    }

    else {
      let val = this.registration.inspectionCounter - 99;
      this.lblmsgn = this.okInvidScrapRef.nativeElement.innerHTML + " " + this.registration.skrotDatum + " " + this.alosRef.nativeElement.innerHTML +"  =  " + val + " ";
      this.toastr.error(this.lblmsgn, 'Error',{enableHtml: false});
      return false;
    }
  }
}

