<div class="panel panel-primary">
    <div class="panel-heading">
        <h4 *ngIf="LoopInfo" class="panel-title">{{'Loop information för ok individ' | translate}}</h4>
        <h4 *ngIf="EventInfo" class="panel-title">{{'Händelser för ok Individ' | translate}}</h4>
        <h4 *ngIf="ScrapInfo" class="panel-title">{{'Skrotade ok Individer' | translate}}</h4>
        <h4 *ngIf="SumInfo" class="panel-title">{{'Summering per ok typ' | translate}}</h4>
        <h4 *ngIf="TotalInfo" class="panel-title">{{'Totalt antal loopar per OK-typ' | translate}}</h4>
    </div>

    <div class="panel-body">
        <div class="row">
            <div class="text-center">
                <div class="form-group">
                    <button type="button" class="btn btn-default"
                        on-click="loopinformation()">{{'Loop information för ok individ' | translate}}</button>
                    <button type="button" class="btn btn-default"
                        on-click="eventinformation()">{{'Händelser för ok Individ' | translate}}</button>
                    <button type="button" class="btn btn-default"
                        on-click="scrapinformation()">{{'Skrotade ok Individer' | translate}}</button>
                    <button type="button" class="btn btn-default"
                        on-click="suminformation()">{{'Summering per ok typ' | translate}}</button>
                    <button type="button" class="btn btn-default"
                        on-click="totalinformation()">{{'Totalt antal loopar per OK-typ' | translate}}</button>
                </div>
            </div>
        </div>

        <router-outlet></router-outlet>
    </div>
</div>