<div class="panel panel-primary">
    <div class="panel-body">

        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke1.jpg" class="img-rounded" alt="Cinque Terre" width="250" height="250"
                style="margin-top: 20px;" height="236">
        </div> -->

        <form class="form-horizontal col-lg-7" #decolorizationForm="ngForm"
            (ngSubmit)="submit(decolorizationForm.value)" role="form">


            <div class="form-group">
                <label for="Klass"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Klass (A =1 , CE =2)' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <div class="btn-group">
                            <select required class="btn btn-default dropdown-toggle" id="classType" ngModel
                                name="classType" #classType=ngModel [(ngModel)]="selectedValue">
                                <option [value]="1">A</option>
                                <option [value]="2">CE</option>
                            </select>
                        </div>
                    </div>
                    <div class="alert alert-danger" *ngIf="classType.touched && !classType.valid">
                        <div *ngIf="classType.errors.required">{{ 'Du måste välja Klass' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="oktype" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Ok typ' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="type" name="type" ngModel
                            #type="ngModel">
                            <option *ngFor="let type of listTypes" [value]="type">{{type}}</option>
                        </select>

                    </div>
                    <div class="alert alert-danger" *ngIf="type.touched && !type.valid">
                        <div *ngIf="type.errors.required">{{ 'Du måste välja oktyp' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{ 'Löpnummer' | translate}}</label>
                <div class="col-lg-2">
                    <input required id="yokeNumber" ngModel name="yokeNumber" type="text" pattern="[0-9]*"
                        #yokeNumber="ngModel" class="form-control" placeholder="">
                    <div class="alert alert-danger" *ngIf="yokeNumber.touched && !yokeNumber.valid">
                        <div *ngIf="yokeNumber.errors.required">{{ 'Du måste skriva in löpnummer' | translate}}</div>
                        <div *ngIf="yokeNumber.errors.pattern">{{ 'Endast nummer !'  | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-offset-5 col-lg-3">
                    <button class="btn btn-success" type="submit" [class.disabled]="!decolorizationForm.valid"
                        id="submitBtn">{{ 'Registrera' | translate}}</button>
                </div>

                <div class="col-lg-2">
                    <button class="btn btn-success" type="button" style="background-color: #033a6a;
                    border-color: #033a6a; margin-left: 0px;"
                        (click)="resetForm(decolorizationForm)">{{'Återställa' | translate   }}</button>
                </div>
            </div>

        </form>
        <span [hidden]="true" #getregistrationlisterror>{{'Något gick fel när du fick registreringslistan. Vänligen försök efter någon gång.' | translate}}</span>
        <span [hidden]="true" #invidscrap>{{'Ok individ är skrotad!' | translate}}</span>
        <span [hidden]="true" #notsentdecolorization>{{'är inte skickad för avfärgning' | translate}}</span>
        <span [hidden]="true" #class>{{'Klass' | translate}}</span>
        <span [hidden]="true" #yoketype>{{'Ok typ' | translate}}</span>
        <span [hidden]="true" #loopnumber>{{'Löpnummer' | translate}}</span>
        <span [hidden]="true" #decolrizedinproduction>{{'har avfärgningsregistrerats och har status I produktion' | translate}}</span>
        <span [hidden]="true" #alos>{{'antal loopar över skrotgräns' | translate}}</span>
    </div>
</div>