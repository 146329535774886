<span [hidden]="true" #elang>{{'poster hittades inte' | translate}}</span>

<script>
    $('#startdatum').datepicker('clearDates');
</script>
<div class="panel panel-primary">
    <div class="panel-body">

        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke7.jpg" class="img-rounded" alt="Cinque Terre" width="250" height="250"
                style="margin-top: 20px;" height="236">
        </div> -->

        <form class="form-horizontal col-lg-7" #showScrapInfoForm="ngForm" autocomplete="off"
            (ngSubmit)="showScrapInfoSubmit(showScrapInfoForm.value)" role="form">


            <div class="form-group">
                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Start datum' | translate}}</label>
                <div class="col-lg-2">
                    
                    <input type="text" required name="startdatum" id="startdatum" 
                        (bsValueChange)="onValueChange($event)" ngModel #startdatum="ngModel" placeholder="YYYY-MM-DD"
                        class="form-control" bsDatepicker [bsConfig]="{ containerClass: 'theme-blue' }">
                    <div class="alert alert-danger" *ngIf="startdatum.touched && !startdatum.valid">
                        <div *ngIf="startdatum.errors.required">{{'Välj datum !' | translate}}</div>
                        <div>{{'Ej giltig datum ex: YYYY-MM-DD' | translate}}</div>
                    </div>
                </div>

            </div>

            <div class="form-group">
                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Slut datum' | translate}}</label>
                <div class="col-lg-2">
                    <input type="text" required name="slutdatum" [minDate]="minDate" id="slutdatum" ngModel
                        #slutdatum="ngModel" placeholder="YYYY-MM-DD" class="form-control" bsDatepicker
                        [bsConfig]="{ containerClass: 'theme-blue' }">
                    <div class="alert alert-danger" *ngIf="slutdatum.touched && !slutdatum.valid">
                        <div *ngIf="slutdatum.errors.required">{{'Välj datum !' | translate}}</div>
                        <div>{{'Ej giltig datum ex: YYYY-MM-DD' | translate}}</div>
                    </div>
                </div>

            </div>


            <div class="form-group">
                <div class="col-lg-offset-8 col-lg-3">
                    <button class="btn btn-success" type="submit" [class.disabled]="!showScrapInfoForm.valid"
                        id="hämtadatabtn3">{{'Hämta data' | translate}}</button>
                </div>
            </div>


        </form>
        <div>
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th class="text-center">{{'Ok-typ' | translate}}</th>
                        <th class="text-center">{{'Löpnummer' | translate}}</th>
                        <th class="text-center">{{'Klass' | translate}}</th>
                        <th class="text-center">{{'Datum' | translate}}</th>
                        <th class="text-center">{{'Skrotorsak' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let scrapinfo of scrapinfolist">
                        <td>{{ scrapinfo.ok_typ }}</td>
                        <td>{{ scrapinfo.lopnummer }}</td>
                        <td>{{ scrapinfo.klass }}</td>
                        <td>{{ scrapinfo.datum }}</td>
                        <td>{{ scrapinfo.skrotorsak }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>