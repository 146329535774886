import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import { SAMLUserModel } from 'src/app/models/saml-user-model';
import { UserService } from './user.service';
import { Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/config/app.config';

export interface User {
    name: string;
}

@Injectable()
export class AuthService {

    constructor(
        private config: AppConfig,
        private _http: Http,
        private _user: UserService,
        private router: Router) { }

    private auth_token = '';
    private user: User = {
        name: ''

    };
    public sUsers: SAMLUserModel;
    environment = 'devtest'
    private apiUrl: string = this.config.get('apiUrl');

    // You can use this operator if you know that the response will always return something than null 
    //this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);


    userAuthenticationAPI = () => environment.production ? window.location.replace(`${this.apiUrl}UserAuthentication/SSO`) : window.location.replace(`${this.apiUrl}UserAuthentication/SSO`);
  
   

    handleError(err: Response) {
        if (err.status === 401) {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('user');
            location.reload();
        } else {
          //  console.log(err);
        }
    }

    //  httpOptions() {
    //      return {
    //          headers: new Headers({
    //              'Authorization': `Bearer ${this._user.getAuthToken()}`,
    //              'Content-Type': 'application/json; charset=UTF-8',
    //          })
    //      };
    //  }

}
