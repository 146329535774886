import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { Response } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from 'src/app/config/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private config: AppConfig, private http: HttpClient) { }
  private apiUrl: string = this.config.get('apiUrl') + 'Instruction';

  loadFiles(allFiles: boolean): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetFiles/?allFiles=${allFiles}`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  viewFile(file: string, id:number): any {

    return this.http.get(`${this.apiUrl}/DownloadFile?file=${file}&id=${id}`, { responseType: 'blob' })
      .map(res => {
        return new Blob([res], { type: "application/pdf" });
      });
  }

  public downloadFile(file: string, id: number): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'GET', `${this.apiUrl}/DownloadFile?file=${file}&id=${id}`, null,
      {
        reportProgress: true,
        responseType: 'blob',

      }));
  }

  errorHandler(error: Response) {
    //console.log(error);
    return throwError(error);
  }


}