import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Supplier } from 'src/app/models/admin-model';
import { ShowSupplierService } from './show-supplier.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-show-supplier',
  templateUrl: './show-supplier.component.html',
  styleUrls: ['./show-supplier.component.css'],
  providers: [ShowSupplierService, ToastrService]
})
export class ShowSupplierComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  isDtInitialized: boolean = false;

  suppliers: Supplier[] = [];
  newSupplier: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  @ViewChild('slang',{static:false}) sDivElementRef: ElementRef;
  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;
  
  constructor(private showSupplierService: ShowSupplierService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getSupplierTypes();
  } 

  getSupplierTypes() {
    this.showSupplierService.getSupplierTypes()
      .subscribe((result) => {
        this.suppliers = result;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        }
        
      },  error => { //This is error part
         // console.log(error.message);
        },
        () => {
         // console.log(this.suppliers);
        }
      )
      }
  

  AddSupplierForm(SupplierModal){

    this.modalService.open(SupplierModal, { centered: true, windowClass: 'window-modal' }); 
     this.newSupplier = {
       supplierId: null,      
      suppliername: null,
      aktiv: null
    }
  }
  
  
  EditSupplierForm(SupplierModal, supplier) {
     
    this.modalService.open(SupplierModal, { centered: true, windowClass: 'window-modal' });

    this.newSupplier={ 
      supplierId: supplier.supplierId,   
      suppliername: supplier.suppliername,     
      aktiv: supplier.aktiv
     };
  }
  

   addSupplierType(form) {
   // console.log(form);
    this.showSupplierService.AddOrUpdateSupplierType(form)
      .subscribe(resp => {
        console.log(resp);
        //this.toastr.success('Supplier Added/Updated Successfully', '');
        this.toastr.success(this.sDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
        this.ngOnInit();
      }, error => {
        //this.toastr.error('Supplier Already Exists', 'Error');
        this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
      });
    this.modalService.dismissAll();

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
