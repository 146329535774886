import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule} from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { Http, HttpModule, XHRBackend, RequestOptions } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { MenuComponent } from './menu/menu.component';
import { from } from 'rxjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
//import { FileUploadModule } from 'ng2-file-upload';
//import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DashboardComponent } from './dashboard/dashboard.component';
import { RegisterComponent } from './register/register.component';
import { ReportsComponent } from './reports/reports.component';
import { AdministrationComponent } from './administration/administration.component';
import { InstructionComponent } from './instruction/instruction.component';
import { GlobalHttpOptions } from './global-http-options';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LoopRegistrationComponent } from './register/loop-registration/loop-registration.component';
import { RegisterDecolorizationComponent } from './register/register-decolorization/register-decolorization.component';
import { RegisterAdjustmentComponent } from './register/register-adjustment/register-adjustment.component';
import { RegisterScrappingComponent } from './register/register-scrapping/register-scrapping.component';
import { RegisterOkyokeComponent } from './register/register-okyoke/register-okyoke.component';
import { EventInformationComponent } from './reports/event-information/event-information.component';
import { LoopInformationComponent } from './reports/loop-information/loop-information.component';
import { ScrapInformationComponent } from './reports/scrap-information/scrap-information.component';
import { SumInformationComponent } from './reports/sum-information/sum-information.component';
import { TotalInformationComponent } from './reports/total-information/total-information.component';
import { ShowAdjustmentComponent } from './administration/show-adjustment/show-adjustment.component';
import { ShowInstructionComponent } from './administration/show-instruction/show-instruction.component';
import { ShowOktypeComponent } from './administration/show-oktype/show-oktype.component';
import { ShowScrapComponent } from './administration/show-scrap/show-scrap.component';
import { ShowSupplierComponent } from './administration/show-supplier/show-supplier.component';
import { ShowUserComponent } from './administration/show-user/show-user.component';
import { AppConfig } from './config/app.config';
import {MatIconModule} from '@angular/material/icon';

import { DataTablesModule } from 'angular-datatables';
import { Ng5SliderModule } from 'ng5-slider';

import { ErrorComponent } from './error/error.component';
import { AuthenticationComponent } from './authentication/authentication.component';

import {TokenInterceptor} from './authentication/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AutofocusDirective } from './autofocus.directive';



export function configFactory(config: AppConfig) {
  return () => config.load();
}

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'YYYY-MM-DD'
  });
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ReportsComponent, 
    AdministrationComponent,
    InstructionComponent,
    DashboardComponent,
    RegisterComponent,
    LoopRegistrationComponent,
    RegisterDecolorizationComponent,
    RegisterAdjustmentComponent,
    RegisterScrappingComponent,
    RegisterOkyokeComponent,
    EventInformationComponent,
    LoopInformationComponent,
    ScrapInformationComponent,
    SumInformationComponent,
    TotalInformationComponent,
    ShowAdjustmentComponent,
    ShowInstructionComponent,
    ShowOktypeComponent,
    ShowScrapComponent,
    ShowSupplierComponent,
    ShowUserComponent,
    ErrorComponent,
    AuthenticationComponent,
    AutofocusDirective 
      
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,MatIconModule,
    HttpModule,AppRoutingModule,HttpClientModule,
    BrowserAnimationsModule,NgbModule,
    // FileUploadModule,PdfJsViewerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      positionClass: 'toast-top-full-width',
      onActivateTick: false
    }),
    BsDatepickerModule.forRoot(),
    DataTablesModule,
    Ng5SliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [AppConfig,
    { provide:  BsDatepickerConfig, useFactory: getDatepickerConfig},  
    { provide: APP_INITIALIZER, useFactory: configFactory, deps: [AppConfig], multi: true },   
    { provide: RequestOptions,  useClass: GlobalHttpOptions },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true  }], 

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
 
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
