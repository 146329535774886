<div *ngIf != isAuthenticated class="full-height content-centered">
    <h1 class="headline">Logging In</h1>
    <p class="headline">Please wait</p>
  </div>
  <!-- <span [hidden]="true" #e1lang>{{'Du har inte behörighet till administratörssidan' | translate}}</span>
  <span [hidden]="true" #e2lang>{{'Du är inte auktoriserad' | translate}}</span> -->
  <div *ngIf =isAuthenticated>
    
        
    
    <app-menu></app-menu>
  </div>
  
  <span [hidden]="true" #e1lang>{{'Du har inte behörighet till administratörssidan' | translate}}</span>
        
  <span [hidden]="true" #e2lang>{{'Du är inte auktoriserad' | translate}}</span>
