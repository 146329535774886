import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';
import { Paint,  LoopRegistrationBE } from 'src/app/models/loop-registration-model';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { Observable,  throwError } from "rxjs"
import { map,  catchError } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DecolorizationService {
  constructor(private config: AppConfig, private http: HttpClient) { }
  private apiUrl: string = this.config.get('apiUrl') + 'Decolorization';
  paint: Paint;

  getYokeTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetYokeTypes`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  getIDreg(classType: number, type: number, yokeNumber: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetIDreg?classType=${classType}&type=${type}&yokeNumber=${yokeNumber}`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  deletePaint(idreg: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/DeletePaint?idreg=${idreg}`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: Response) {
    //console.log(error);
    return throwError(error);
  }


  getRegistrationList(klass: number, type: number, yokeNumber: number) {
    // class, type, yokeno
    let regVariable: LoopRegistrationBE;
    let date = '2009-03-23';
    regVariable = {
      yokeNumber: 2025,
      classtype: 2,
      type: 3,
      inspectionCounter: 20,
      totalCounter: 68,
      interval: 20,
      totalCount: 280,
      skrotad: 0,
      painted: 7267,
      counterDate: new Date(date),
      intStatus: null,
      skrotDatum: new Date(date),
      aktiv: null
    };
    return regVariable;
  }
}
