<div class="panel panel-primary">
    <div class="panel-body">

        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke4.jpg" class="img-circle" alt="Cinque Terre" width="250" height="250">
        </div> -->

        <form class="form-horizontal col-lg-7" #registerIndividForm="ngForm" autocomplete="off"
            (ngSubmit)="registerIndividFormSubmit(registerIndividForm.value)" role="form">
            <div class="form-group">
                <label for="oktype" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Ok typ' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="type" name="type" ngModel
                            #type="ngModel">
                            <option *ngFor="let type of listTypes" [value]="type">{{type}}</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="type.touched && !type.valid">
                        <div *ngIf="type.errors.required">{{ 'Du måste välja Klass' | translate}}</div>
                    </div>
                </div>

            </div>

            <div class="form-group">
                <label for="Löpnummer" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Löpnummer Från' |
                    translate}}</label>
                <div class="col-lg-2">
                    <input required id="startdatum" ngModel name="startdatum" type="text" pattern="[0-9]*"
                        #startdatum="ngModel" class="form-control" placeholder="">
                    <div class="alert alert-danger" *ngIf="startdatum.touched && !startdatum.valid">
                        <div *ngIf="startdatum.errors.required">{{ 'Du måste skriva in löpnummer' | translate}}</div>
                        <div *ngIf="startdatum.errors.pattern">{{ 'Endast nummer !' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="Löpnummer" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Löpnummer Till' |
                    translate}}</label>
                <div class="col-lg-2">
                    <input id="slutdatum" ngModel name="slutdatum" type="text" pattern="[0-9]*" #slutdatum="ngModel"
                        class="form-control" placeholder="">
                    <!-- <div class="alert alert-danger" *ngIf="slutdatum.touched && !slutdatum.valid">
                        <div *ngIf="slutdatum.errors.required">{{ 'Du måste skriva in löpnummer' | translate}}</div>
                        <div *ngIf="slutdatum.errors.pattern">{{ 'Endast nummer !' | translate}}</div>
                    </div> -->
                </div>
            </div>


            <div class="form-group">
                <label for="Löpnummer" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Leverantör' |
                    translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="leverantor" name="leverantor"
                            ngModel #leverantor="ngModel">
                            <option *ngFor="let val of listLeverantor" [value]="val.supplierId">
                                {{val.supplierId + " " + val.suppliername}}</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="leverantor.touched && !leverantor.valid">
                        <div *ngIf="leverantor.errors.required">{{ 'Du måste välja leverantor !' | translate}}</div>
                    </div>
                </div>

            </div>

            <div class="form-group">
                <div class="col-lg-offset-5 col-lg-3">
                    <button class="btn btn-success" type="submit" [class.disabled]="!registerIndividForm.valid"
                        id="submitBtn">{{ 'Registrera' | translate}}</button>
                </div>

                <div class="col-lg-2">
                    <button class="btn btn-success" type="button" style="background-color: #033a6a;
                    border-color: #033a6a;margin-left: 0px;" (click)="resetForm(registerIndividForm)">{{'Återställa' |
                        translate }}</button>
                </div>

            </div>


            <!-- <div class="form-group">
                <div class="col-lg-offset-8 col-lg-3">
                        <button class="btn btn-success" (click)="submit(loopRegistrationForm.value)" type="submit" 
                        [class.disabled]="!loopRegistrationForm.valid" id="submitBtn">{{ 'Registrera' | translate}}</button>
                    <br>
                    <br>
                    
                        <button class="btn btn-success" type="button" (click)="resetForm(loopRegistrationForm)">{{'Återställa' | translate}}</button>
                   
                </div>
            </div> -->
            <span [hidden]="true" #getyokeerror>{{'fel i att få YokeNumber' | translate}}</span>
            <span [hidden]="true" #addyokeregistererror>{{'fel i Lägga till Yoke Register' | translate}}</span>
            <span [hidden]="true" #addyokeregistererror>{{'fel i att lägga till Yoke Inspection' | translate}}</span>
            <span [hidden]="true" #yoketype>{{'Ok typ' | translate}}</span>
            <span [hidden]="true" #loopnumber>{{'Löpnummer' | translate}}</span>
            <span [hidden]="true" #supplier>{{'Leverantör' | translate}}</span>
            <span [hidden]="true" #scrapnoregistration>{{'är skrotad, ingen registering gjord' | translate}}</span>
            <span [hidden]="true" #alreadyregistered>{{'är redan registrerad, Ingen registering gjord' |
                translate}}</span>




            <div class="text-right">
                <div class="form-group">
                    <p id="showmessage"></p>

                    <script>
                        function myFunction() {
                            document.getElementById("showmessage").innerHTML = "Klass CE OK typ 2 löpnummer 44 registrerad. Totalt antal loopar 3";
                            document.getElementById("showmessage").style.color = "green";
                            document.getElementById("Löpnummer").style.backgroundColor = "lightgreen";
                            document.getElementById("okType").style.backgroundColor = "lightgreen";
                            document.getElementById("Klass").style.backgroundColor = "lightgreen";
                        }
                    </script>
                </div>
            </div>
        </form>

    </div>
</div>