<span [hidden]="true" #elang>{{'poster hittades inte' | translate}}</span>

<div class="panel panel-primary">
    <div class="panel-body">

        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke8.jpg" class="img-rounded" alt="Cinque Terre" width="250" height="250"
                style="margin-top: 20px;" height="236">
        </div> -->

        <form class="form-horizontal col-lg-7" #showSumInfoForm="ngForm" autocomplete="off"
            (ngSubmit)="showSumInfoSubmit(showSumInfoForm.value)" role="form">


            <div class="form-group">

                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Start datum' | translate}}</label>
                <div class="col-lg-2">
                    <input type="text" required name="startdatum"  id="startdatum" ngModel
                        #startdatum="ngModel" placeholder="YYYY-MM-DD" (bsValueChange)="onValueChange($event)"
                        class="form-control" bsDatepicker
                        [bsConfig]="{  dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">
                    <div class="alert alert-danger" *ngIf="startdatum.touched && !startdatum.valid">
                        <div *ngIf="startdatum.errors.required">{{'Välj datum !' | translate}}</div>
                        <div>{{'Ej giltig datum ex: YYYY-MM-DD' | translate}}</div>
                    </div>

                </div>
            </div>

            <div class="form-group">
                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Slut datum' | translate}}</label>
                <div class="col-lg-2">
                    <input type="text" required name="slutdatum" [minDate]="minDate" id="slutdatum" ngModel
                        #slutdatum="ngModel" placeholder="YYYY-MM-DD" class="form-control" bsDatepicker
                        [bsConfig]="{  dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">
                    <div class="alert alert-danger" *ngIf="slutdatum.touched && !slutdatum.valid">
                        <div *ngIf="slutdatum.errors.required">{{'Välj datum !' | translate}}</div>
                        <div>{{'Ej giltig datum ex: YYYY-MM-DD' | translate}}</div>
                    </div>
                </div>
            </div>


            <div class="form-group">
                <div class="col-lg-offset-8 col-lg-3">
                    <button class="btn btn-success" type="submit" [class.disabled]="!showSumInfoForm.valid"
                        id="hämtadatabtn4">{{'Hämta data' | translate}}</button>
                </div>
            </div>


        </form>


        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-bordered table-striped table-hover">
            <thead>
                <tr>
                    <th class="text-center">{{'OK-typ' | translate}}</th>
                    <th class="text-center">{{'Antal nyregistrerade ok individer' | translate}}</th>
                    <th class="text-center">{{'Antal skrotade' | translate}}</th>
                    <th class="text-center">{{'Antal loop registreringar' | translate}}</th>
                    <th class="text-center">{{'Antal ej skrotade ok individer' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let suminfo of suminfolist">
                    <td>{{ suminfo.type }}</td>
                    <td>{{ suminfo.newlyReg }}</td>
                    <td>{{ suminfo.scrappedNo }}</td>
                    <td>{{ suminfo.loopRegistration }}</td>
                    <td>{{ suminfo.nonScrappedNo }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr *ngIf="suminfolist?.length != 0">
                    <th class="footer">Totalt</th>
                    <th class="footer">{{ newlyRegSum }}</th>
                    <th class="footer">{{ scrappedNoSum }}</th>
                    <th class="footer">{{ loopRegSum }}</th>
                    <th class="footer">{{ nonScrappedNoSum }}</th>
                </tr>
            </tfoot>

        </table>
    </div>
</div>