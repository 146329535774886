import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UploadService } from 'src/app/instruction/upload.service'
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Files } from 'src/app/models/admin-model';
import { AppConfig } from '../../config/app.config';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-show-instruction',
  templateUrl: './show-instruction.component.html',
  styleUrls: ['./show-instruction.component.css'],
  providers: [UploadService]
})
export class ShowInstructionComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  public progress: number;
  public message: string;
  fileToUpload: File = null;
  pdfFiles: Files[] = [];
  allFiles: boolean = true;

  @ViewChild('slang',{static:false}) sDivElementRef: ElementRef;
  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;
  

  constructor(private uploadService: UploadService,
    private http: HttpClient,
    private config: AppConfig,
    private toastr: ToastrService) {

  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [0, 'desc']

    };
    this.loadFiles()
  }

  loadFiles() {
    this.uploadService.loadFiles(this.allFiles)
      .subscribe((result) => {
        this.pdfFiles = result;
        console.log(this.pdfFiles);
        this.rerender();
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
         // console.log(this.pdfFiles);
        }
      )
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  viewFile(file, id) {

    this.uploadService
      .viewFile(file, id)
      .subscribe(data => {
        console.log(data);
        const fileUrl = URL.createObjectURL(data);
        window.open(fileUrl, '_self', 'width=900, height=500, top=100, bottom=100, left=100, right=100', true);
      });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  upload() {
    var files = this.fileToUpload;
    if (files.size === 0)
      return;
    const formData = new FormData();
    formData.append(files.name, files);
    console.log(formData);
    const uploadReq = new HttpRequest('POST', this.config.get('apiUrl') + 'Instruction/Upload', formData, {
      reportProgress: true, withCredentials: true
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = event.body.toString();
        this.ngOnInit();
        
        if(this.message == "Only pdf files are allowed.")
        {
          this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
        }
        else{
          this.toastr.success(this.sDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
        }
      }
    }, error => {
      this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
    });
  }

  public download(fileName, id) {
    // this.downloadStatus.emit( {status: ProgressStatusEnum.START});
    this.uploadService.downloadFile(fileName, id).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            //  this.downloadStatus.emit( {status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100)});
            break;
          case HttpEventType.Response:
            // this.downloadStatus.emit( {status: ProgressStatusEnum.COMPLETE});
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
        //  this.downloadStatus.emit( {status: ProgressStatusEnum.ERROR});
      }
    );
  }

}
