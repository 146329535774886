import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoopRegistrationBE, Paint, YokeInspection } from 'src/app/models/loop-registration-model';
import { LoopRegistrationService } from '../loop-registration/loop-registration.service';
import { DecolorizationService } from './register-decolorization.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-register-decolorization',
  templateUrl: './register-decolorization.component.html',
  styleUrls: ['./register-decolorization.component.css'],
  providers: [DecolorizationService]
})
export class RegisterDecolorizationComponent implements OnInit {

  @ViewChild('getregistrationlisterror',{static:false}) getRegistrationListErrorRef: ElementRef;
  @ViewChild('invidscrap',{static:false}) invidScrapRef: ElementRef;
  @ViewChild('notsentdecolorization',{static:false}) notSentDecolorizationRef: ElementRef;
  @ViewChild('class',{static:false}) classRef: ElementRef;
  @ViewChild('yoketype',{static:false}) yokeTypeRef: ElementRef;
  @ViewChild('loopnumber',{static:false}) loopNumberRef: ElementRef;
  @ViewChild('decolrizedinproduction',{static:false}) decolrizedInProductionRef: ElementRef;
  @ViewChild('alos',{static:false}) alosRef: ElementRef;

  constructor(private decolorizationService: DecolorizationService, private loopRegistrationService: LoopRegistrationService, private toastr: ToastrService) { }


  selectedValue="2";
  listTypes: any;
  registration: LoopRegistrationBE;
  Klass: string;
  typnummer: number;
  check1: boolean;
  check: boolean;
  txtlooptotAvf: number;
  txtloop20Avf: number;
  idreg: number;
  lblmsgn: string;
  yokeinspection: YokeInspection;
  currentDate = new Date();
  date: any;

  ngOnInit() {
    this.resetForm();
    this.getYokeTypesList();
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.registration = {
      classtype: null,
      type: null,
      yokeNumber: null,
      totalCount: null,
      totalCounter: null,
      skrotad: null,
      interval: null,
      painted: null,
      inspectionCounter: null,
      intStatus: null,
      counterDate: null,
      skrotDatum: null,
      aktiv: null
    }
  }

  submit(form) {
    //debugger;
    this.check = false;
    this.check1 = false;
    this.registration.classtype = parseInt(form.classType);
    this.registration.type = parseInt(form.type);
    this.registration.yokeNumber = parseInt(form.yokeNumber);
    //console.log(this.registration);

    this.decolorizationService.getIDreg(this.registration.classtype, this.registration.type, this.registration.yokeNumber)
      .subscribe((result) => {
      //  console.log(result);
        this.getIDregResponse(result);
      },
        error => { //This is error part
        //  console.log(error.message);
          //this.toastrService.error('error', 'Something went wrong while getting the customers, Please try after sometime.');
        },
        () => {

        }
      )
  }

  getYokeTypesList() {
    this.decolorizationService.getYokeTypes()
      .subscribe((result) => {
        this.listTypes = result;
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
         // console.log(this.listTypes);
        }
      )
  }

  getIDregResponse(idreg: number) {
    if (idreg > 0) {
      this.registration.painted = idreg;
      this.check1 = true;
    }
    this.check1 = false;
    this.loopRegistrationService.getPaintedCheck(this.registration.painted)
      .subscribe(resp => {
       // console.log(resp.body);
        this.getPaintedCheckResponse(resp.body);
      },
        error => { //This is error part
         // console.log(error.message);
          if (this.check1 === false) {
            if (this.registration.classtype == 2)
              this.Klass = "CE";

            if (this.registration.classtype == 1)
              this.Klass = "A";

            this.lblmsgn = this.classRef.nativeElement.innerHTML + " " + this.Klass + " " + this.yokeTypeRef.nativeElement.innerHTML + " " + this.registration.type + " " + this.loopNumberRef.nativeElement.innerHTML + " " + this.registration.yokeNumber + " " + this.notSentDecolorizationRef.nativeElement.innerHTML;
            this.toastr.error(this.lblmsgn, 'Error', {enableHtml:false});
            return;
          }          
        },
        () => {
         // console.log(this.listTypes);
        });
  }


  getPaintedCheckResponse(paint: Paint) {
    if (paint != null)
      this.check1 = true;
    this.decolorizationService.deletePaint(this.registration.painted)
      .subscribe((result) => {
       // console.log(result);
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
          //  This is Success part
          //this.toastr.success('success', 'Customer has been deleted successfully. Now you can delete more customer , if you wish too.');

        }
      )

    this.registration.intStatus = 2;

    this.loopRegistrationService.getRegistrationList(this.registration.classtype, this.registration.type, this.registration.yokeNumber)
      .subscribe(resp => {
       // console.log(resp.body);
        this.getRegResponse(resp.body);
      },
        error => { //This is error part
         // console.log(error.message);
        //  this.toastr.error('error', 'Something went wrong while getting the registration list, Please try after sometime.');
          this.toastr.error(this.getRegistrationListErrorRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
        },
        () => {
          //  This is Success part        

        }
      );
  }


  getRegResponse(reg: LoopRegistrationBE) {
    if (reg != null) {
      this.check1 = true;
      this.txtloop20Avf = reg.inspectionCounter;
      this.registration.inspectionCounter = this.txtloop20Avf;
      this.txtlooptotAvf = reg.totalCounter;
      this.registration.totalCounter = this.txtlooptotAvf;
      this.registration.interval = reg.interval;
      this.registration.totalCount = reg.totalCount;
      this.registration.skrotad = reg.skrotad;
      this.registration.painted = reg.painted;
      this.registration.counterDate = reg.counterDate;

      if (this.registration.classtype == 2)
        this.Klass = "CE";
      if (this.registration.classtype == 1)
        this.Klass = "A";

      if (this.registration.skrotad == 4) {
        this.lblmsgn = this.invidScrapRef.nativeElement.innerHTML + " " + this.registration.skrotad;
        this.toastr.error(this.lblmsgn, 'Error', {enableHtml:false});
        return;
      }

      if (this.txtloop20Avf >= this.registration.interval && this.txtlooptotAvf < this.registration.totalCount) {
        this.registration.inspectionCounter = 0;
        this.registration.totalCounter = this.txtlooptotAvf + 0;

        //update YokeRegister 
        this.loopRegistrationService
          .updateYokeRegister(this.registration.inspectionCounter, this.registration.totalCounter, this.registration.classtype, this.registration.type, this.registration.yokeNumber)
          .subscribe(resp => {
           // console.log(resp);
          });

        this.date = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');
        //Insert YokeInspection
        this.yokeinspection = {
          id: this.registration.painted,
          class: this.registration.classtype,
          type: this.registration.type,
          yokeNumber: this.registration.yokeNumber,
          status: 6,
          errorTypeId: 0,
          yokeComment: 0,
          inspectionCounter: this.registration.inspectionCounter,
          totalcounter: this.registration.totalCounter,
          inspectDate: this.date
        };

        this.loopRegistrationService.addYokeInspection(this.yokeinspection)
          .subscribe(resp => {
      // console.log(resp);
          });

        this.lblmsgn =this.classRef.nativeElement.innerHTML + " " + this.Klass + " " + this.yokeTypeRef.nativeElement.innerHTML + " " + this.registration.type + " " + this.loopNumberRef.nativeElement.innerHTML + " " + this.registration.yokeNumber + " " + this.decolrizedInProductionRef.nativeElement.innerHTML;
        this.toastr.success(this.lblmsgn,'Success', {enableHtml:false});
        return;
      }

      else if (this.registration.inspectionCounter >= 99) {
        this.registration.inspectionCounter = this.registration.inspectionCounter + 1;

        //update YokeRegister 
        this.loopRegistrationService
          .updateYokeRegister(this.registration.inspectionCounter, this.registration.totalCounter, this.registration.classtype, this.registration.type, this.registration.yokeNumber)
          .subscribe(resp => {
           // console.log(resp);
          });
        let insCntr = this.registration.inspectionCounter - 99;
        this.lblmsgn = this.invidScrapRef.nativeElement.innerHTML + " " + this.registration.skrotDatum + " " +this.alosRef.nativeElement.innerHTML + " " + insCntr + " ";
        this.toastr.success(this.lblmsgn,'Success', {enableHtml:false});
        return;
      }
    }
  }
}















