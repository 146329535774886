import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import { SumInformationService } from './sum-information.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SumInformation, ReportDate } from 'src/app/models/reports-model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sum-information',
  templateUrl: './sum-information.component.html',
  styleUrls: ['./sum-information.component.css'],
  providers: [DatePipe]
})
export class SumInformationComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  startDate: string;
  endDate: string;
  newlyRegSum: number;
  scrappedNoSum: number;
  loopRegSum: number;
  nonScrappedNoSum: number;
  reportDate: ReportDate;
  dtOptions: any = {};
  suminfolist: SumInformation[] = [];
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;
  
  constructor(private sumInformationService: SumInformationService, private datePipe: DatePipe, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.resetForm();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'print',
        'excel',
        'csv'
      ]
    };
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.reportDate = {
      startdatum: '',
      slutdatum: ''
    }
  }
  minDate: Date;
  onValueChange(value: Date): void {
    this.minDate = value;
    //console.log(this.minDate);
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }


  showSumInfoSubmit(form) {
   // console.log(form);
    this.startDate = this.transformDate(form.startdatum);
    this.endDate = this.transformDate(form.slutdatum);
   // console.log(this.startDate, this.endDate);

    if ((this.startDate != null && this.endDate != null) && (this.endDate) < (this.startDate)) {
      this.toastr.error('End date should be greater than start date.');
      this.suminfolist = [];
      this.rerender();
      return false;
    }

    
      this.sumInformationService.getSumInfoList(this.startDate, this.endDate)
        .subscribe((result) => {
          this.suminfolist = [];
          this.suminfolist = result;
          console.log(this.suminfolist);
          this.newlyRegSum = this.suminfolist.map(t => t.newlyReg).reduce((a, value) => a + value, 0);
          this.scrappedNoSum = this.suminfolist.map(t => t.scrappedNo).reduce((a, value) => a + value, 0);
          this.nonScrappedNoSum = this.suminfolist.map(t => t.nonScrappedNo).reduce((a, value) => a + value, 0);
          this.loopRegSum = this.suminfolist.map(t => t.loopRegistration).reduce((a, value) => a + value, 0);

          this.rerender();
        },
            error => { //This is error part
              console.log(error.message);
              this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
              //this.toastr.error('poster hittades inte', 'Error');
            },
            () => {
              console.log(this.suminfolist);
              if(this.suminfolist.length === 0)
              this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
              //this.toastr.error('poster hittades inte', 'Error');
            }
          )
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
