<div class="panel panel-primary">
    <div class="panel-body">

        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke2.jpg" class="img-rounded" alt="Cinque Terre" width="250" height="250"
                style="margin-top: 20px;" height="236">
        </div> -->

        <form class="form-horizontal col-lg-7" #correctiveForm="ngForm"
            (ngSubmit)="correctiveSubmit(correctiveForm.value)" role="form">


            <div class="form-group">
                <label for="Klass"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Klass (A =1 , CE =2)' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="class" ngModel name="class"
                            #class=ngModel [(ngModel)]="selectedValue ">
                            <option [value]="1">A</option>
                            <option [value]="2">CE</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="class.touched && !class.valid">
                        <div *ngIf="class.errors.required">{{ 'Du måste välja Klass' | translate}}</div>
                    </div>
                </div>

            </div>

            <div class="form-group">
                <label for="oktype" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Ok typ' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="type" name="type" ngModel
                            #type="ngModel">
                            <option *ngFor="let type of listTypes" [value]="type">{{type}}</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="type.touched && !type.valid">
                        <div *ngIf="type.errors.required">{{ 'Du måste välja oktyp' | translate}}</div>
                    </div>
                </div>

            </div>

            <div class="form-group">
                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{ 'Löpnummer' | translate}}</label>
                <div class="col-lg-2">
                    <input required id="yokeNumber" ngModel name="yokeNumber" type="text" pattern="[0-9]*"
                        #yokeNumber="ngModel" class="form-control" placeholder="">
                    <div class="alert alert-danger" *ngIf="yokeNumber.touched && !yokeNumber.valid">
                        <div *ngIf="yokeNumber.errors.required">{{ 'Du måste skriva in löpnummer' | translate}}</div>
                        <div *ngIf="yokeNumber.errors.pattern">{{ 'Endast nummer !'  | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{ 'Justertyp' | translate}}</label>


                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="justertyp" name="justertyp" ngModel
                            #justertyp="ngModel">
                            <option *ngFor="let jstTyp of listJustertyp" [value]="jstTyp.errorTypeId">
                                {{jstTyp.errorTypeId +" "+ jstTyp.errorType}}</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="justertyp.touched && !justertyp.valid">
                        <div *ngIf="justertyp.errors.required">{{ 'Du måste välja justertyp !' | translate}}</div>
                    </div>
                </div>


            </div>


            <div class="form-group">
                <div class="col-lg-offset-5 col-lg-3">
                    <button class="btn btn-success" type="submit" [class.disabled]="!correctiveForm.valid"
                        id="registreraBtn">{{ 'Registrera' | translate }}</button>
                </div>

                <div class="col-lg-2">
                    <button class="btn btn-success" type="button" style="background-color: #033a6a;
                    border-color: #033a6a;margin-left: 0px;"
                        (click)="resetForm(correctiveForm)">{{'Återställa' | translate}}</button>
                </div>
            </div>

            <span [hidden]="true" #getadjustmenterror>{{'Fel i att få justeringstyp' | translate}}</span>
            <span [hidden]="true"
                #incorrectinvid>{{'Ok individ är ej Registrerad eller fel inmatat' | translate}}</span>
            <span [hidden]="true" #addinspectionerror>{{'fel i att lägga till Yoke Inspection' | translate}}</span>
            <span [hidden]="true" #updateregistererror>{{'fel i Uppdatering av okregistret' | translate}}</span>
            <span [hidden]="true" #adjusttype>{{'Justertyp' | translate}}</span>
            <span [hidden]="true" #regclass>{{'registrerad för klass' | translate}}</span>
            <span [hidden]="true" #yoketype>{{'Ok typ' | translate}}</span>
            <span [hidden]="true" #loopnumber>{{'Löpnummer' | translate}}</span>
            <span [hidden]="true" #okinvidscrap>{{'Ok  individ skrotad' | translate}}</span>
            <span [hidden]="true" #alos>{{'antal loopar över skrotgräns' | translate}}</span>

            <div class="text-right">
                <div class="form-group">
                    <p id="showmessage"></p>

                    <script>
                        function myFunction() {
                            document.getElementById("showmessage").innerHTML = "Klass CE OK typ 2 löpnummer 44 registrerad. Totalt antal loopar 3";
                            document.getElementById("showmessage").style.color = "green";
                            document.getElementById("Löpnummer").style.backgroundColor = "lightgreen";
                            document.getElementById("okType").style.backgroundColor = "lightgreen";
                            document.getElementById("Klass").style.backgroundColor = "lightgreen";
                        }
                    </script>
                </div>
            </div>
        </form>

    </div>
</div>