import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { User } from 'src/app/models/admin-model';
import { ShowUserService } from './show-user.service';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-show-user',
  templateUrl: './show-user.component.html',
  styleUrls: ['./show-user.component.css'],
  providers: [ShowUserService]
})

export class ShowUserComponent implements OnInit {
 
  @ViewChild(DataTableDirective, { static: false })
  
  dtElement: DataTableDirective;

  isDtInitialized: boolean = false;
  
  dtOptions: DataTables.Settings = {};
  users: User[] = [];
  dtTrigger: Subject<any> = new Subject<any>();
  newUser: any = {};
  userTypes: string[] = new Array("user", "admin");
  isReadOnly : boolean = false;
  
  @ViewChild('slang',{static:false}) sDivElementRef: ElementRef;
  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;

  constructor(private modalService: NgbModal, private showUserService: ShowUserService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getUsers();

  }

  getUsers() {
    this.showUserService.getUser()
      .subscribe((result) => {
        this.users = result;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        }
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
         // console.log(this.users);
        }
      )
  }

  showAddUserForm(UserModal) {

    this.modalService.open(UserModal, { centered: true, windowClass: 'window-modal' });
    this.newUser = {
      id: null, 
      iDright: null,
      rights: null
    }
    this.isReadOnly = false;
  }



  showEditUserForm(editUserModal, addUser) {
    this.modalService.open(editUserModal, { centered: true, windowClass: 'window-modal' });

    this.newUser = {
      id: addUser.id,
      iDright: addUser.iDright,
      rights: addUser.rights
    }
    this.isReadOnly = true;
  }



  addUser(form) {
   // console.log(form);
    this.showUserService.addOrUpdateUserLogin(form)
      .subscribe(resp => {
        console.log(resp);
        // this.toastr.success('User Added/Updated Successfully', '');
        this.toastr.success(this.sDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
        this.ngOnInit();
      }, error => {
       // this.toastr.error('UserName Already Exists', 'Error');
        this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
      });
    this.modalService.dismissAll();

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
