import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from "rxjs"
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from 'src/app/config/app.config';
import { User } from 'src/app/models/admin-model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ShowUserService {
  constructor(private http: HttpClient, private config: AppConfig) { }
  private apiUrl: string = this.config.get('apiUrl') + 'Admin';
  user: User[];

  getUser(): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetUserLogin`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  getUsersFromData(): User[] {
    return this.user;
  }

  addOrUpdateUserLogin(user: User): Observable<any> {
    // console.log(user);
    return this.http.post(`${this.apiUrl}/AddOrUpdateUserLogin`, user, httpOptions);

  }

  errorHandler(error: Response) {
    // console.log(error);  
    return throwError(error);
  }


}
