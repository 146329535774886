<span [hidden]="true" #elang>{{'poster hittades inte' | translate}}</span>

<div class="panel panel-primary">
    <div class="panel-body">

        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke6.jpg" class="img-rounded" alt="Cinque Terre" width="250" height="250"
                style="margin-top: 20px;" height="236">
        </div> -->

        <form class="form-horizontal col-lg-7" #showEventInfoForm="ngForm"
            (ngSubmit)="getReportEventInfo(showEventInfoForm.value)" role="form">

            <div class="form-group">
                <label for="Klass"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Klass (A =1 , CE =2)' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="class" ngModel name="class"
                            #class=ngModel [(ngModel)]="selectedValue">
                            <option [value]="1">A</option>
                            <option [value]="2">CE</option>
                        </select>

                    </div>
                    <div class="alert alert-danger" *ngIf="class.touched && !class.valid">
                        <div *ngIf="class.errors.required">{{'Du måste välja Klass' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="oktype" class="col-lg-offset-4 col-lg-4 control-label">{{'Ok typ' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="type" name="type" ngModel
                            #type="ngModel">
                            <option *ngFor="let type of listTypes" [value]="type">{{type}}</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="type.touched && !type.valid">
                        <div *ngIf="type.errors.required">{{'Du måste välja oktyp' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="Löpnummer"
                    class="col-lg-offset-4 col-lg-4 control-label">{{'Löpnummer' | translate}}</label>
                <div class="col-lg-2">
                    <input required id="yokeNumber" ngModel name="yokeNumber" type="text" pattern="[0-9]*"
                        #yokeNumber="ngModel" class="form-control" placeholder="" style="width: 200PX;">
                    <div class="alert alert-danger" *ngIf="yokeNumber.touched && !yokeNumber.valid">
                        <div *ngIf="yokeNumber.errors.required">{{'Du måste skriva in löpnummer' | translate}}</div>
                        <div *ngIf="yokeNumber.errors.pattern">{{'Endast nummer !' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-offset-8 col-lg-3">
                    <button class="btn btn-success" type="submit" [class.disabled]="!showEventInfoForm.valid"
                        id="hämtadatabtn1">{{'Hämta data' | translate}}</button>
                </div>
            </div>
        </form>

        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-bordered table-striped table-hover">
            <thead>
                <tr>
                    <th class="text-center">{{'Händelser' | translate}}</th>
                    <th class="text-center">{{'Datum' | translate}}</th>
                    <th class="text-center">{{'Status' | translate}}</th>
                    <th class="text-center">{{'Justeråtgärd' | translate}}</th>
                    <th class="text-center">{{'Totalt_antal_loopar' | translate}}</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let info of eventInfo">
                    <td>{{ info.handelser }}</td>
                    <td>{{ info.datum }}</td>
                    <td>{{ info.status }}</td>
                    <td>{{ info.justeratgard }}</td>
                    <td>{{ info.totalt_antal_loopar }}</td>
                </tr>
            </tbody>

        </table>

    </div>
</div>