import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ShowOkTypeService } from './show-oktype.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { YokeTypes } from 'src/app/models/admin-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-show-oktype',
  templateUrl: './show-oktype.component.html',
  styleUrls: ['./show-oktype.component.css'],
  providers: [ShowOkTypeService, ToastrService]
})
export class ShowOktypeComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  yokeTypes: YokeTypes[] = [];
  dtTrigger: Subject<any> = new Subject<any>();
  newYokeType: any = {};
  date: Date;
  currentDate: any;
  
  @ViewChild('slang',{static:false}) sDivElementRef: ElementRef;
  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;
  
  constructor(private showOkTypeService: ShowOkTypeService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }


  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.getOkType();
  }
  

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getOkType() {

    this.showOkTypeService.getYokeTypes()
      .subscribe((result) => {
        this.yokeTypes = result;
        this.dtTrigger.next();
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
         // console.log(this.yokeTypes);
        }
      )
  }

  AddOkTypeForm(OkTypeModal) {

    this.modalService.open(OkTypeModal, { centered: true, windowClass: 'window-modal' });
    this.newYokeType = {
      id: null, 
      type: null,
      interval: null,
      totalCount: null,
      description: null,      
      aktiv: null
    }

  }

  EditOkTypeForm(OkTypeModal, okType) {

    this.modalService.open(OkTypeModal, { centered: true, windowClass: 'window-modal' });

    this.newYokeType = {
      id: okType.id, 
      type: okType.type,
      interval: okType.interval,
      totalCount: okType.totalCount,
      description: okType.description,
      aktiv: okType.aktiv
    }
  }

  addYokeType(form) {
    this.date = new Date();
    this.currentDate = formatDate(this.date, 'yyyy-M-d', 'en-US');
   // console.log(form);
    form.changedate = this.currentDate;
    form.regdate = this.currentDate;
    this.showOkTypeService.addOrUpdateYokeType(form)
      .subscribe(resp => {
        console.log(resp);
        this.toastr.success(this.sDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
       // this.toastr.success('Yoke Type Added/Updated Successfully', '');
        this.ngOnDestroy();
        this.ngOnInit();
      }, error => {
        this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
       // this.toastr.error('Yoke Type Already Exists', 'Error');

      });
    this.modalService.dismissAll();

  }




}
