import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})

export class ReportsComponent{

  LoopInfo : boolean = false ;
  EventInfo : boolean = false ;
  ScrapInfo : boolean = false ;
  SumInfo : boolean = false;
  TotalInfo : boolean = false ;
  constructor(private route: ActivatedRoute, private router: Router, public translate: TranslateService) { }

  title: string = null;

 
  loopinformation() {
    this.router.navigate(['loopinformation'], { relativeTo: this.route });
    this.title = "Loop information för ok individ";
    this.LoopInfo = true;
    this.EventInfo = false;
    this.ScrapInfo = false;
    this.SumInfo = false;
    this.TotalInfo = false ;
  }

  eventinformation() {
    this.router.navigate(['eventinformation'], { relativeTo: this.route });
    this.title = "Händelser för ok individ";
    this.LoopInfo = false;
    this.EventInfo = true;
    this.ScrapInfo = false;
    this.SumInfo = false;
    this.TotalInfo = false ;
  }

  scrapinformation() {
    this.router.navigate(['scrapinformation'], { relativeTo: this.route });
    this.title = "Skrotade ok individer";
    this.LoopInfo = false;
    this.EventInfo = false;
    this.ScrapInfo = true;
    this.SumInfo = false;
    this.TotalInfo = false ;
  }

  suminformation() {
    this.router.navigate(['suminformation'], { relativeTo: this.route });
    this.title = "Summering per ok typ";
    this.LoopInfo = false;
    this.EventInfo = false;
    this.ScrapInfo = false;
    this.SumInfo = true;
    this.TotalInfo = false ;
  }
  
  totalinformation() {
    this.router.navigate(['totalinformation'], { relativeTo: this.route });
    this.title = "Totalt antal loopar per OK-typ";
    this.LoopInfo = false;
    this.EventInfo = false;
    this.ScrapInfo = false;
    this.SumInfo = false;
    this.TotalInfo = true ;
  }
}
