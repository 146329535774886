import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { LoopRegistrationBE, Paint, YokeInspection } from 'src/app/models/loop-registration-model';
import { YokeSkrot } from 'src/app/models/register-scrapping-model'
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};

@Injectable()
export class RegisterScrappingService {
  constructor(private config: AppConfig, private http: HttpClient) { }

  private apiUrl: string = this.config.get('apiUrl') + 'RegisterScrapping';
  paint: Paint;

  getYokeTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetYokeTypes`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  getScrappingList(classType: number, type: number, yokeNumber: number): Observable<HttpResponse<LoopRegistrationBE>> {
    return this.http.get<LoopRegistrationBE>(`${this.apiUrl}/GetScrapping?classType=${classType}&type=${type}&yokeNumber=${yokeNumber}`, { observe: 'response' })
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );

  }

  getYokeScrappingType(): Observable<HttpResponse<YokeSkrot>> {
    return this.http.get<YokeSkrot>(`${this.apiUrl}/GetYokeScrappingType`, { observe: 'response' })
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );

  }


  addYokeInspection(yokeInspection: YokeInspection): Observable<any> {
    return this.http.post(`${this.apiUrl}/AddYokeInspection`, yokeInspection, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  updateYokeRegister(inspectionCntr: number, skrotError: number, totalCounter: number, classType: number, type: number, yokeNumber: number): Observable<HttpResponse<any>> {

    return this.http.post<any>(`${this.apiUrl}/UpdateYokeRegister?inspectionCntr=${inspectionCntr}&skrotError=${skrotError}&&totalCounter=${totalCounter}&&classType=${classType}&type=${type}&yokeNumber=${yokeNumber}`, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: Response) {
    //console.log(error);
    return throwError(error);
  }

}
