<span [hidden]="true" #elang>{{'Endast PDF-filer är tillåtet.' | translate}}</span>
        
        <span [hidden]="true" #slang>{{'Överför Lyckad' | translate}}</span>

        
<table class="upload">
    <h2 style="font-family: Scania Sans Bold, Helvetica, Arial, Sans-serif;">{{ 'Ladda upp manual' | translate}}</h2>
    <br>
    <br>
    <div class="input-group">

        <form (ngSubmit)="upload()">
            <div class="form-group col-sm-3 offset-sm-3">
                <input #file type="file" (change)="handleFileInput($event.target.files)" />

            </div>
            <div class="form-group col-sm-3">
                <button class="btn btn-success">{{ 'Ladda upp' | translate}}</button>

            </div>

        </form>
    </div>
    <br>
    <p style="font-size: 14px; margin-left: 40%; color: #033a6a; margin-left: -5%;">
        {{ '*Ladda upp dokument bara i .pdf-format.' | translate}}</p> <br>

</table>

<br>
<br>


<div class="col-md-10 offset-md-1">
    <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger">
        <thead>
            <tr>
                <th style="display:none;" class="text-center" scope="col"></th>
                <th class="text-center" scope="col">{{'Filnamn' | translate}}</th>
                <th class="text-center" scope="col">{{ 'Senast uppdaterad' | translate}}</th>

                <th class="text-center" scope="col">{{'Se' | translate}}</th>
                <th class="text-center" scope="col">{{'Spara' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of pdfFiles">
                <td scope="row" style="display:none;">{{ item.item3 }}</td>
                <td scope="row">{{ item.item1 }}</td>
                <td scope="row">{{ item.item2 | date}}</td>

                <td>
                    <button type="button" class="btn btn-primary" (click)="viewFile(item.item1, item.item3)">
                        {{'Se' | translate}}
                    </button>
                </td>
                <td>
                    <button type="button" class="btn btn-success" (click)="download(item.item1, item.item3)">
                        {{'Spara' | translate}}
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>