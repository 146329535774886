import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import {  LoopRegistrationBE,  YokeInspection } from 'src/app/models/loop-registration-model';
import { YokeErrorTypes } from 'src/app/models/admin-model'
import { Observable, throwError } from 'rxjs';
import { map,  catchError } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};

@Injectable()
export class RegisterAdjustmentService {

  constructor(private config: AppConfig, private http: HttpClient) { }

  private apiUrl: string = this.config.get('apiUrl') + 'RegisterAdjustment';
  getYokeTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetYokeTypes`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  getRegistrationList(classType: number, type: number, yokeNumber: number): Observable<HttpResponse<LoopRegistrationBE>> {
    return this.http.get<LoopRegistrationBE>(`${this.apiUrl}/GetAdjustment?classType=${classType}&type=${type}&yokeNumber=${yokeNumber}`, { observe: 'response' })
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );

  }

  addYokeInspection(yokeInspection: YokeInspection): Observable<any> {
    return this.http.post(`${this.apiUrl}/AddYokeInspection`, yokeInspection, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  updateYokeRegister(inspectionCntr: number, classType: number, type: number, yokeNumber: number): Observable<HttpResponse<any>> {

    return this.http.post<any>(`${this.apiUrl}/UpdateYokeRegister?inspectionCntr=${inspectionCntr}&classType=${classType}&type=${type}&yokeNumber=${yokeNumber}`, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  getAjustmentType(): Observable<HttpResponse<YokeErrorTypes>> {
    return this.http.get<YokeErrorTypes>(`${this.apiUrl}/GetAdjustmentType`, { observe: 'response' })
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: Response) {
    console.log(error);
    return throwError(error);
  }
}
