import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'ng5-slider';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Okra';

  apiValues: string[] = [];

  constructor(private _service: Http, public translate: TranslateService) {
    translate.addLangs(['en', 'sv']);
    translate.setDefaultLang('sv');

  }

  ngOnInit() {


  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  value: number = 0.1;
  options: Options = {
    floor: 0.1,
    ceil: 3.0
  };

  showInfo(event: Event, value) {
    console.log(value);
    $('.userText').css('font-size', value + 'em')
  }

}
