import { Component, OnInit } from '@angular/core';
import { UploadService } from 'src/app/instruction/upload.service'
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Files } from '../models/admin-model';




@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.css'],
  providers: [UploadService]
})
export class InstructionComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  public progress: number;
  public message: string;
  fileToUpload: File = null;
  pdfFiles: Files[] = [];
  downloadedFile: any;
  allFiles: boolean = false;


  constructor(private uploadService: UploadService,
    private http: HttpClient,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.loadFiles()
  }




  loadFiles() {
    this.uploadService.loadFiles(this.allFiles)
      .subscribe((result) => {
        this.pdfFiles = result;
        this.dtTrigger.next();
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
          //console.log(this.pdfFiles);
        }
      )
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  file: string;
  viewFile(file, id) {
    this.uploadService
      .viewFile(file, id)
      .subscribe(data => {
        console.log(data);
        const fileUrl = URL.createObjectURL(data);
        window.open(fileUrl, '_self', 'width=900, height=500, top=100, bottom=100, left=100, right=100', true);
      });
  }


  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }


  public download(fileName, id) {
    this.uploadService.downloadFile(fileName, id).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            break;
          case HttpEventType.Response:
            this.downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(this.downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
        //  this.downloadStatus.emit( {status: ProgressStatusEnum.ERROR});
      }
    );
  }
}
