import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { YokeErrorTypes} from 'src/app/models/admin-model';
import { ShowAdjustmentService } from './show-adjustment.service';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';



@Component({
  selector: 'app-show-adjustment',
  templateUrl: './show-adjustment.component.html',
  styleUrls: ['./show-adjustment.component.css'],
  providers: [ShowAdjustmentService]
})
export class ShowAdjustmentComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  isDtInitialized: boolean = false;

  dtOptions: DataTables.Settings = {};
  errortypes: YokeErrorTypes[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  newAdjustment: any = {};

  @ViewChild('slang',{static:false}) sDivElementRef: ElementRef;
  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;
  
  constructor(private showAdjustmentService: ShowAdjustmentService, private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.getYokeErrorTypesList();

  }


  getYokeErrorTypesList() {
    this.showAdjustmentService.getYokeErrorTypes()
      .subscribe((result) => {
        this.errortypes = result;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        }
      },
        error => { //This is error part
         // console.log(error.message);
        },
        () => {
         // console.log(this.errortypes);
        }
      )
  }

  showAddAdjustmentForm(errorTypeModal) {

    this.modalService.open(errorTypeModal, { centered: true, windowClass: 'window-modal' });
    this.newAdjustment = {
      errorType: null,
      errorTypeId: null
    }

  }
  showEditAdjustmentForm(editErrorTypeModal, addError) {
    this.modalService.open(editErrorTypeModal, { centered: true, windowClass: 'window-modal' });
    this.newAdjustment = {
      errorType: addError.errorType,
      errorTypeId: addError.errorTypeId
    }
  }

  addErrorType(form) {
   // console.log(form);
    this.showAdjustmentService.addOrUpdateErrorType(form)
    .subscribe(resp => {
      console.log(resp);
     // this.toastr.success('ErrorType Added/Updated Successfully', '');
      this.toastr.success(this.sDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
        
      this.ngOnInit();
    }, error => {
      this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
      //this.toastr.error('ErrorType Already Exists', 'Error');
    } );
    this.modalService.dismissAll();

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
