import { Component, HostListener, ElementRef, Input, OnInit, ApplicationRef, ViewContainerRef, ViewChild } from '@angular/core';
import { UserService } from '../authentication/user.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ShepherdService } from 'angular-shepherd';

export interface User {
  name: string;
  adminUser: string;
  userId: string,
  emailId: string
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [ShepherdService]
})


export class MenuComponent implements OnInit {

  public user: User = {
    name: '',
    adminUser: '',
    userId: '',
    emailId: ''
  };

  currentUser: string;
  isadmin: boolean = true;
  
  @ViewChild('welcomeokra',{static:false}) welcomeOkraRef: ElementRef;
  @ViewChild('welcomeokratext',{static:false}) welcomeOkraTextRef: ElementRef;

  @ViewChild('register',{static:false}) registerRef: ElementRef;
  @ViewChild('registertext',{static:false}) registerTextRef: ElementRef;

  @ViewChild('report',{static:false}) reportRef: ElementRef;
  @ViewChild('reporttext',{static:false}) reportTextRef: ElementRef;

  @ViewChild('admin',{static:false}) adminRef: ElementRef;
  @ViewChild('admintext',{static:false}) adminTextRef: ElementRef;

  @ViewChild('instructionmanual',{static:false}) instructionmanualRef: ElementRef;
  @ViewChild('instructionmanualtext',{static:false}) instructionmanualTextRef: ElementRef;

  @ViewChild('language',{static:false}) languageRef: ElementRef;
  @ViewChild('languagetext',{static:false}) languageTextRef: ElementRef;

  @ViewChild('swlanguagetext',{static:false}) swlanguagetextRef: ElementRef;

  constructor(private userService: UserService, public translate: TranslateService, private modalService: NgbModal, private shepherdService: ShepherdService) {
    translate.addLangs(['en', 'sv']);
    translate.setDefaultLang('sv');
  }

  ngOnInit() {

    this.user = this.userService.getUser();

    if (this.user && this.user.name && this.user.adminUser) {
      this.currentUser = this.user.name;
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  Intro() {
    this.shepherdService.defaultStepOptions = {
      classes: 'custom-class-name-1 custom-class-name-2',
      scrollTo: false,
      cancelIcon: {
        enabled: true
      }
    };
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;


    this.shepherdService.addSteps([
      {
        id: 'intro',
        attachTo: {
          element: '.first-element',
          on: 'right'
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        },

        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Exit',
            type: 'cancel'
          },

          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next'
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'class-1 class-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: this.welcomeOkraRef.nativeElement.innerHTML,
        text: this.welcomeOkraTextRef.nativeElement.innerHTML,

      },
      {
        id: 'Register',
        attachTo: {
          element: '.second-element',
          on: 'bottom'
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        },

        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Exit',
            type: 'cancel'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            type: 'back'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next'
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: this.registerRef.nativeElement.innerHTML,
        text: this.registerTextRef.nativeElement.innerHTML,

      },
      {
        id: 'Report',
        attachTo: {
          element: '.third-element',
          on: 'bottom'
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        },

        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Exit',
            type: 'cancel'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            type: 'back'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next'
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: this.reportRef.nativeElement.innerHTML,
        text: this.reportTextRef.nativeElement.innerHTML,
      },
      {
        id: 'Admin',
        attachTo: {
          element: '.forth-element',
          on: 'bottom'
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        },

        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Exit',
            type: 'cancel'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            type: 'back'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next'
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: this.adminRef.nativeElement.innerHTML,
        text: this.adminTextRef.nativeElement.innerHTML
      },
      {
        id: 'Register',
        attachTo: {
          element: '.fifth-element',
          on: 'bottom'
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        },

        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Exit',
            type: 'cancel'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            type: 'back'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next'
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: this.instructionmanualRef.nativeElement.innerHTML,
        text: this.instructionmanualTextRef.nativeElement.innerHTML
      },
      {
        id: 'Language1',
        attachTo: {
          element: '.sixth-element',
          on: 'left'
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        },

        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Exit',
            type: 'cancel'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            type: 'back'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next'
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: this.languageRef.nativeElement.innerHTML,
        text: this.languageTextRef.nativeElement.innerHTML

      },
      {
        id: 'Language2',
        attachTo: {
          element: '.seventh-element',
          on: 'left'
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        },

        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Exit',
            type: 'cancel'
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            type: 'back'
          },

        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: this.languageRef.nativeElement.innerHTML,
        text: this.swlanguagetextRef.nativeElement.innerHTML

      }
    ]);

    this.shepherdService.start();
  }

  isPopUserOpen(popUser) {
    try {

      this.modalService.open(popUser, { size: 'sm', windowClass: 'window-modal' });
      //  return this.popUser.isOpen();
    } catch (error) {
      return false;
    }
  }

}
