import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './authentication.service';
import { UserService } from './user.service';
import { Http } from '@angular/http';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
  providers: [AuthService, UserService]
})
export class AuthenticationComponent implements OnInit {

  decodeResp: any;
  samlResponse: any
  jsoneResponse: any;
  isAuthenticated: boolean = false;
  response = {
    Data: [] as { [key: string]: any }, Status: ''
  };
  thirdUrl: string = null;
  fourthUrl: string = null;

  constructor(private _authservice: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _http: Http,
    private _user: UserService) { }


  ngOnInit() {
    if (this._user.getAuthToken()) {
      if (localStorage.getItem('state_url') != "") {
        this.router.parseUrl(localStorage.getItem('state_url'));
        this.isAuthenticated = true;
      }
      else {
        this.router.navigate(['dashboard']);
        this.isAuthenticated = true;
      }
     } else {
      this.route.queryParams.subscribe(param => {
        if (param) {
          this.response = JSON.parse(param.Response)[0].Response;

        }
     });
      if (!window.location.href.split('=')[1]) {
        this.isAuthenticated = false;
        this._authservice.userAuthenticationAPI();       
      }

      this.samlResponse = window.location.href.split('=')[1]
      // console.log(this.samlResponse);

      if (this.samlResponse)
        this.decodeResp = decodeURI(this.samlResponse)
      // console.log(this.decodeResp);

      if (this.decodeResp)
        this.jsoneResponse = JSON.parse(this.decodeResp.slice(1, -1))


      this.route.queryParams.subscribe(param => {
        if (this.jsoneResponse) {
          this.response = JSON.parse(param.Response)[0].Response;

        }
      });
      setTimeout(() => {
        if (this.jsoneResponse.Response.Status === '') {
          this._authservice.userAuthenticationAPI();
          this.router.navigate(['dashboard']);
        } else {
          if (this.response.Status === 'Failure') {
            this.router.navigate(['error']);
          }
          if (this.jsoneResponse.Response.Status === 'Success') {
            this.isAuthenticated = true;
           // console.log(this.jsoneResponse);
            const resultToken = this.jsoneResponse.Response.Data.Result.token;
            const resultName = this.jsoneResponse.Response.Data.Result.employeeName;
            const adminUser = this.jsoneResponse.Response.Data.Result.adminUser;
            const userId = this.jsoneResponse.Response.Data.Result.userId;
            const emailId = this.jsoneResponse.Response.Data.Result.emailId;
            this._user.sUsers = this.jsoneResponse.Response.Data.Result;
            this._user.setAuthToken(resultToken);
            this._user.setUser(resultName, adminUser, userId, emailId);
           // console.log(resultName);
           //  console.log(this._user.sUsers);

            this.router.navigate(['dashboard']);
          }
        }
      }, 0);
    }

  }


}
