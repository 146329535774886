import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from "rxjs"
import { map, catchError } from 'rxjs/operators';
import { YokeSkrot } from 'src/app/models/admin-model';
import { AppConfig } from 'src/app/config/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ShowScrapService {

  constructor(private config: AppConfig, private http: HttpClient) { }
  private apiUrl: string = this.config.get('apiUrl') + 'Admin';

  yokeskrot: YokeSkrot[];

  getYokeSkrotList(): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetYokeSkrotList`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  addOrUpdateYokeSkrot(yokeSkrot: YokeSkrot): Observable<any> {

    return this.http.post(`${this.apiUrl}/AddOrUpdateYokeSkrot`, yokeSkrot, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: Response) {
    // console.log(error);  
    return throwError(error);
  }

}
