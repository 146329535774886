import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Response } from '@angular/http';
import { Paint, YokeInspection, LoopRegistrationBE } from 'src/app/models/loop-registration-model';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config/app.config';
import { IReturnObject } from '../../shared/return-object.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class LoopRegistrationService {
    constructor(private config: AppConfig, private http: HttpClient) { }

  private apiUrl: string = this.config.get('apiUrl') + 'LoopRegistration';

    getRegistrationList(classType: number, type:number, yokeNumber: number): Observable<HttpResponse<LoopRegistrationBE>> {
      return this.http.get<LoopRegistrationBE>(`${this.apiUrl}/GetLoopRegistration?classType=${classType}&type=${type}&yokeNumber=${yokeNumber}`, { observe: 'response' });
          
    } 

    getPaintedCheck1(idreg: number): Observable<IReturnObject> {
      return this.http.get(`${this.apiUrl}/GetPaintedChek/?idreg=${idreg}`)
          .map((response: Response) => <IReturnObject>response.json());
    }
    getPaintedCheck(idreg: number): Observable<HttpResponse<Paint>> {
        return this.http.get<Paint>(`${this.apiUrl}/GetPaintedChek?idreg=${idreg}`, { observe: 'response' });
    }
    
    addYokeInspection(ypkeInspection: YokeInspection): Observable<any> {
      
      return this.http.post(`${this.apiUrl}/AddYokeInspection`, ypkeInspection, httpOptions);
    }

    updateYokeRegister(inspectionCntr: number, totalCntr: number, classType: number, type: number, yokeNumber: number): Observable<HttpResponse<any>>{
      
      return this.http.post<any>(`${this.apiUrl}/UpdateYokeRegister?inspectionCntr=${inspectionCntr}&totalCntr=${totalCntr}&classType=${classType}&type=${type}&yokeNumber=${yokeNumber}`, httpOptions);
    }

    addPaint(paint: Paint): Observable<any> {
      
      return this.http.post(`${this.apiUrl}/AddPaint`, paint, httpOptions);
    }



}