<span [hidden]="true" #slang>{{'Ok Fel Typ Tillagt/Uppdaterad Lyckades' | translate}}</span>
        
        <span [hidden]="true" #elang>{{'Ok Fel Typ Redan Finns' | translate}}</span>


<div class="container">
    <div id="wrapper">
        <button class="btn btn-success" (click)="showAddAdjustmentForm(ErrorTypeModal)"><i class="fa fa-plus"></i>
            {{'Lägg till Justertyp' | translate}}</button>
    </div>
    <br />
    <br />
    <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-bordered table-striped table-hover">
            <thead>
                <tr>
                    <th class="text-center">{{ 'Justeråtgärd' | translate}}</th>
                    <th class="text-center">{{ 'Redigera' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let error of errortypes">
                    <td>{{ error.errorType }}</td>
                    <td class="text-right" style="width: 85px;">
                        <button class="btn btn-info mr-2" (click)="showEditAdjustmentForm(ErrorTypeModal, error)">
                            <i class="fa fa-edit"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #ErrorTypeModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{ 'Lägg till / uppdatera Justeråtgärd' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>


        <form class="form-horizontal" #addErrorTypeForm="ngForm" (ngSubmit)="addErrorType(addErrorTypeForm.value)"
            role="form">
            <div class="modal-body">
                <div class="container">

                    <input type="hidden" name="errorTypeId" name="errorTypeId" ngModel #errorTypeId="ngModel"
                        [(ngModel)]="newAdjustment.errorTypeId" />
                    <div class="form-group">

                        <label for="errortypelbl"
                            class="col-sm-4 col-form-label ">{{ 'Justeråtgärd' | translate}}</label>
                        <div col-lg-2>
                            <input type="text" name="errorType" type="text" name="errorType" ngModel
                                #errorType="ngModel" [(ngModel)]="newAdjustment.errorType"
                                pattern="^[a-zA-Z0-9ÅåÄäÖö]+(\s+[A-Za-z0-9ÅåÄäÖö\(\)]+)*$" required />
                            <div class="alert alert-danger col-form-label" *ngIf="errorType.touched && !errorType.valid">
                                <div *ngIf="errorType.errors.required">
                                    {{'Inmatningsfält Justertyp får ej vara blank.' |translate}}</div>
                                <div *ngIf="errorType.errors.pattern">{{'Ogiltiga tecken !' | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success" type="submit" [class.disabled]="!addErrorTypeForm.valid"
                    id="hämtadatabtn4">{{'Spara' | translate}}</button>
                <button type="button" class="btn btn-primary" style="background-color: #033a6a;
                border-color: #033a6a;" (click)="modal.close('Save click')">{{'Avbryt' | translate}}</button>
            </div>

        </form>

    </ng-template>