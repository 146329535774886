import { Injectable, Injector } from '@angular/core';
import { Http } from '@angular/http';

import { Observable } from '../../../node_modules/rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class AppConfig {
    private _config: Object;
    private _env: Object;
    private http: Http;

    constructor( private injector: Injector ) {
        this.http = injector.get(Http);
    }

    load() {
       return new Promise((resolve, reject) => {
            this.http.get('assets/env.json')
                .map(res => res.json())
                .subscribe((env_data) => {
                    this._env = env_data;
                    this.http.get('assets/config.' + env_data.env + '.json')
                        .map(res => res.json())
                        .catch((error: any) => {
                            console.error(error);
                            return Observable.throw(error.json().error || 'Server error');
                        })
                        .subscribe((data) => {
                            this._config = data;
                            resolve(true);
                        });
                });
        });
    }

    getEnv(key: any) {
        return this._env[key];
    }

    get(key: any) {
        return this._config[key];
    }
};
