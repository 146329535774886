import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ReportsReg, TotalInformation } from 'src/app/models/reports-model';
import { NgForm } from '@angular/forms';
import { TotalInformationService } from './total-information.service';
import { DecolorizationService } from 'src/app/register/register-decolorization/register-decolorization.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-total-information',
  templateUrl: './total-information.component.html',
  styleUrls: ['./total-information.component.css']
})
export class TotalInformationComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;

  constructor(private scrapInformationService: TotalInformationService, private decolorizationService: DecolorizationService, private toastr: ToastrService) { }
  dtOptions: any = {};
  totalinfolist: TotalInformation[] = [];
  dtTrigger: Subject<any> = new Subject<any>();
  loopReg: ReportsReg;
  listTypes: any;

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'print',
        'excel',
        'csv'
      ]
    };
    this.getYokeTypes();
    this.resetForm();
  }


  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.loopReg = {
      class: null,
      type: null,
      yokeNumber: null
    }

  }


  showTotalInfoSubmit(form) {
   // console.log(form);
    this.loopReg.type = parseInt(form.type);
    //console.log(this.loopReg);
    this.scrapInformationService.getTotalInfoList(this.loopReg.type)
      .subscribe((result) => {
        this.totalinfolist = [];
        this.totalinfolist = result;
        this.rerender();
      },
        error => { //This is error part
         // console.log(error.message);
          this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
          //this.toastr.error('poster hittades inte', 'Error');
        },
        () => {
          //console.log(this.totalinfolist);
          if(this.totalinfolist.length === 0)
          this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
          //this.toastr.error('poster hittades inte', 'Error');
          })
  }

  getYokeTypes() {
    this.decolorizationService.getYokeTypes()
      .subscribe((result) => {
        this.listTypes = result;
      },
        error => { //This is error part
         // console.log(error.message);
          //this.toastrService.error('error', 'Something went wrong while getting the customers, Please try after sometime.');
        },
        () => {
         // console.log(this.listTypes);
        }
      )
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}