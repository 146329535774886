import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from "rxjs"
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from 'src/app/config/app.config';
import { YokeTypes } from 'src/app/models/admin-model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})

export class ShowOkTypeService {
  constructor(private config: AppConfig, private http: HttpClient) { }
  private apiUrl: string = this.config.get('apiUrl') + 'Admin';
  yokeTypes: YokeTypes[];

  getYokeTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetYokeTypes`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  addOrUpdateYokeType(yokeTypes: YokeTypes): Observable<any> {
    return this.http.post(`${this.apiUrl}/AddorUpdateYokeType`, yokeTypes, httpOptions);

  }


  errorHandler(error: Response) {
    console.log(error);
    return throwError(error);
  }

}