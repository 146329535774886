import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoopRegistrationBE, Paint, YokeInspection } from 'src/app/models/loop-registration-model';
import { LoopRegistrationService } from './loop-registration.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-loop-registration',
  templateUrl: './loop-registration.component.html',
  styleUrls: ['./loop-registration.component.css'],
  providers: [LoopRegistrationService],
})
export class LoopRegistrationComponent implements OnInit {

  @ViewChild('getregistrationerror', { static: false }) getRegistrationErrorRef: ElementRef;
  @ViewChild('paintedcheckerror', { static: false }) paintedCheckErrorRef: ElementRef;
  @ViewChild('reachedscraperror', { static: false }) reachedScrapErrorRef: ElementRef;
  @ViewChild('scrapinvid', { static: false }) scrapinvidErrorRef: ElementRef;
  @ViewChild('invidmissing', { static: false }) invidMissingErrorRef: ElementRef;
  @ViewChild('yokeinspectionadded', { static: false }) yokeinspectionaddedRef: ElementRef;
  @ViewChild('class', { static: false }) classRef: ElementRef;
  @ViewChild('yoketype', { static: false }) yokeTypeRef: ElementRef;
  @ViewChild('loopnumber', { static: false }) loopNumberRef: ElementRef;
  @ViewChild('rtal', { static: false }) rtalRef: ElementRef;

  constructor(private loopRegistrationService: LoopRegistrationService, private toastr: ToastrService) { }
  selectedValue: number;
  ngOnInit(): void {
    this.resetForm();
    this.selectedValue = 2;

  }

  focusOnLoopNumber(type) {
    var length = type.value != null ? parseInt(type.value) : 0;
    if (length > 0)
      document.getElementById("yokeNumber").focus();
  }

  focusOnSubmit(classType, type, yokenumber) {
    var classTypelength = classType.value != null ? parseInt(classType.value) : 0;
    var typelength = type.value != null ? parseInt(type.value) : 0;
    var yokenumberlength = yokenumber.value != null ? parseInt(yokenumber.value) : 0;
    if (classTypelength > 0 && typelength > 0 && yokenumberlength > 0)
      document.getElementById("submitBtn").focus();
  }

  focusOnType(classType) {
    var length = classType.value != null ? parseInt(classType.value) : 0;
    if (length > 0)
      document.getElementById("type").focus();
  }

  handleEnterKeyPress(event) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName !== 'textarea') {
      return false;
    }
  }

  reglist: LoopRegistrationBE[] = [];
  check: boolean = false;
  check1: boolean;
  paintedcheck: any;
  lblmsgn: string;
  txtlooptot: number;
  txtloop20: number;
  Klass: string;
  registration: LoopRegistrationBE = undefined;
  paint: Paint = undefined;
  yokeinspection: YokeInspection = undefined;
  currentDate = new Date();
  date: any;


  resetFields(form: NgForm) {
    const keepValues = [
      form.controls.classType.value,
    ];
    form.reset();
    form.controls.classType.patchValue(keepValues[0]);
    document.getElementById("type").focus();
    this.txtlooptot = null;
    this.txtloop20 = null;

  }


  resetForm(form?: NgForm) {
    
    if (form != null)
      form.reset();
      this.registration = {
      classtype: null,
      type: null,
      yokeNumber: null,
      totalCount: null,
      totalCounter: null,
      skrotad: null,
      interval: null,
      painted: null,
      inspectionCounter: null,
      intStatus: null,
      counterDate: null,
      skrotDatum: null,
      aktiv: null
    }
    this.txtlooptot = null;
    this.txtloop20 = null;
  }

  submit(form) {
    this.registration.classtype = parseInt(form.classType);
    this.registration.type = parseInt(form.type);
    this.registration.yokeNumber = parseInt(form.yokeNumber);
    document.getElementById("type").focus();
    this.date = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');
    this.loopRegistrationService.getRegistrationList(this.registration.classtype, this.registration.type, this.registration.yokeNumber)
      .subscribe(resp => {
        //console.log(resp.body);
        this.getRegResponse(resp.body);
      },
        error => { //This is error part
          //  this.lblmsgn = "Ok individ finns inte registrerad, OK typ ej aktiv / eller fel inmatat!";
          //Ok individual is not registered, OK type not active / or incorrectly entered!
          // this.toastr.error(this.lblmsgn, 'Error');
          this.toastr.error(this.getRegistrationErrorRef.nativeElement.innerHTML, '', { enableHtml: false });
          return;
        },
        () => {

        });
  }

  getRegResponse(reg: LoopRegistrationBE) {
    //console.log(reg);
    if (reg !== null) {
      this.txtlooptot = reg.totalCounter; //Reg
      this.txtloop20 = reg.inspectionCounter;//Reg

      if (reg.yokeNumber > 0)
        this.check = true;

      this.registration.painted = reg.painted;
      this.check1 = false;

      this.loopRegistrationService.getPaintedCheck(this.registration.painted)
        .subscribe(resp => {
          // console.log(resp.body);
          if (resp.body != null)
            this.check1 = true;
          //Ok individual is not decolorized and put into Production
          //  this.lblmsgn = "Ok individ är ej avfärgningsregistrerad och satt i Produktion";
          //  this.toastr.error(this.lblmsgn, 'Error');
          this.toastr.error(this.paintedCheckErrorRef.nativeElement.innerHTML, '', { enableHtml: false });
          return;

        });

      //Ok inivid saknas i register(Ok inivid missing in register)
      if (reg.yokeNumber !== this.registration.yokeNumber) {
        //this.lblmsgn = "Ok individ saknas i register!";//Ok individual missing in register!
        //this.toastr.error(this.lblmsgn, 'Error');
        this.toastr.error(this.invidMissingErrorRef.nativeElement.innerHTML, '', { enableHtml: false });
        return;
      }

      if (reg.skrotad === 4) {
        //Ok individual Is scrapped!
        //  this.lblmsgn = "Ok individ är skrotad!" + reg.counterDate;
        // this.toastr.error(this.lblmsgn, 'Error');
        this.toastr.error(this.scrapinvidErrorRef.nativeElement.innerHTML, '', { enableHtml: false });
        return;
      }

      if (reg.totalCounter + 1 >= reg.totalCount) {
        // this.lblmsgn = "Ok individ har uppnått skrotningsgräns, SKA skrotningsRegistreras och skrotas";
        //Ok individual has reached scrapping limit, SHOULD be scrapped and scrapped
        //  this.toastr.error(this.lblmsgn, 'Error');
        this.toastr.error(this.reachedScrapErrorRef.nativeElement.innerHTML, '', { enableHtml: false });
        return;
      }
      if (this.registration.yokeNumber === reg.yokeNumber && reg.counterDate !== null) {
        this.registration.inspectionCounter = this.txtloop20;
        this.registration.totalCounter = this.txtlooptot;
        this.registration.interval = reg.interval;
        this.registration.totalCount = reg.totalCount;
        this.registration.skrotad = reg.skrotad;
        this.registration.painted = reg.painted;
      }

    }
    this.updateInfo();

  }

  updateInfo() {
    if (this.registration.inspectionCounter + 1 >= this.registration.interval) {
      if (this.registration.classtype === 2)
        this.Klass = "CE";
      else if (this.registration.classtype === 1)
        this.Klass = "A";
      this.registration.inspectionCounter = this.registration.inspectionCounter + 1;
      this.registration.totalCounter = this.registration.totalCounter + 1;
      //update YokeRegister 
      this.loopRegistrationService
        .updateYokeRegister(this.registration.inspectionCounter, this.registration.totalCounter, this.registration.classtype, this.registration.type, this.registration.yokeNumber)
        .subscribe(resp => {
          //  console.log(resp);
        });

      this.yokeinspection = {
        id: this.registration.painted,
        class: this.registration.classtype,
        type: this.registration.type,
        yokeNumber: this.registration.yokeNumber,
        status: 5,
        errorTypeId: 0,
        yokeComment: 0,
        inspectionCounter: this.registration.inspectionCounter,
        totalcounter: this.registration.totalCounter,
        inspectDate: this.date
      };

      //Insert YokeInspection
      this.loopRegistrationService.addYokeInspection(this.yokeinspection)
        .subscribe(resp => {
          // console.log(resp);
        });
      let totcount = this.registration.totalCount + 1;

      this.lblmsgn = this.classRef.nativeElement.innerHTML + this.Klass + this.yokeTypeRef.nativeElement.innerHTML + this.registration.type + this.loopNumberRef.nativeElement.innerHTML + this.registration.yokeNumber + this.yokeinspectionaddedRef.nativeElement.innerHTML + totcount;
      this.toastr.success(this.lblmsgn, '', { enableHtml: false });

      this.paint = {
        indatum: this.date,
        IDreg: this.registration.painted,
        utdatum: null
      };


      //Insert into TblPaint
      this.loopRegistrationService.addPaint(this.paint)
        .subscribe(resp => {
          // console.log(resp);
        });
      return;
    }

    //Reg table  yoke table
    if (this.registration.totalCount + 1 >= this.registration.totalCounter) {
      if (this.registration.classtype === 2)
        this.Klass = "CE";
      else if (this.registration.classtype === 1)
        this.Klass = "A";
      this.registration.inspectionCounter = this.txtloop20 + 1;
      this.registration.totalCounter = this.txtlooptot + 1;
      //update YokeRegister 
      this.loopRegistrationService
        .updateYokeRegister(this.registration.inspectionCounter, this.registration.totalCounter, this.registration.classtype, this.registration.type, this.registration.yokeNumber)
        .subscribe(resp => {
          // console.log(resp);
        });
      let count = this.txtloop20 + 1;
      this.lblmsgn = this.classRef.nativeElement.innerHTML + " " + this.Klass + " " + this.yokeTypeRef.nativeElement.innerHTML + " " + this.registration.type + " " + this.loopNumberRef.nativeElement.innerHTML + " " + this.registration.yokeNumber + " " + this.rtalRef.nativeElement.innerHTML + " " + count;
      this.toastr.success(this.lblmsgn, '', { enableHtml: false });

      //Insert YokeInspection
      this.yokeinspection = {
        id: this.registration.painted,
        class: this.registration.classtype,
        type: this.registration.type,
        yokeNumber: this.registration.yokeNumber,
        status: 2,
        errorTypeId: 0,
        yokeComment: 0,
        inspectionCounter: this.txtloop20 + 1,
        totalcounter: this.txtlooptot,
        inspectDate: this.date
      };

      this.loopRegistrationService.addYokeInspection(this.yokeinspection)
        .subscribe(resp => {
          // console.log(resp);
        });
      return;
    }
  }

}
