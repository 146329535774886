<div class="panel panel-primary">
    <div class="panel-heading">
        <h4 *ngIf="LoopReg" class="panel-title">{{'Loop Registering Av Ok Individ' | translate}}</h4>
        <h4 *ngIf="Decolor" class="panel-title">{{'Registrera Avfargning' | translate}}</h4>
        <h4 *ngIf="Adjust" class="panel-title">{{'Registrera Justeratgard' | translate}}</h4>
        <h4 *ngIf="ScrapVar" class="panel-title">{{'Registrera Skrotning' | translate}}</h4>
        <h4 *ngIf="YokeVar" class="panel-title">{{'Registrera Ny Ok Individ' | translate}}</h4>
    </div>

    <div class="panel-body">
        <div class="row">
            <div class="text-center">
                <div class="form-group">
                    <button type="button" class="btn btn-default test"
                        on-click="loopregistration()">{{ 'Loop Registering Av Ok Individ' | translate}}</button>
                    <button type="button" class="btn btn-default test"
                        on-click="decolorization()">{{ 'Registrera Avfargning' | translate}}</button>
                    <button type="button" class="btn btn-default test"
                        on-click="adjustment()">{{ 'Registrera Justeratgard' | translate}}</button>
                    <button type="button" class="btn btn-default test"
                        on-click="scrap()">{{ 'Registrera Skrotning' | translate}}</button>
                    <button type="button" class="btn btn-default test"
                        on-click="yoke()">{{ 'Registrera Ny Ok Individ' | translate}}</button>
                </div>
            </div>
        </div>

        <router-outlet></router-outlet>
    </div>
</div>