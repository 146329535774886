<div class="panel panel-primary">
    <div class="panel-body">

        <!-- <div class="container col-lg-1">
            <img src="./assets/yoke3.jpg" class="img-rounded" alt="Cinque Terre" width="250" height="250"
                style="margin-top: 20px;" height="236">
        </div> -->

        <form class="form-horizontal col-lg-7" #scrappingForm="ngForm" (ngSubmit)="submit(scrappingForm.value)"
            role="form">

            <div class="form-group">
                <label for="Klass" class="col-lg-offset-4 col-lg-4 control-label">{{'Klass (A =1 , CE =2)' |
                    translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="classType" ngModel name="classType"
                            #classType=ngModel [(ngModel)]="selectedValue">
                            <option [value]="1">A</option>
                            <option [value]="2">CE</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="classType.touched && !classType.valid">
                        <div *ngIf="classType.errors.required">{{ 'Du måste välja Klass' | translate}}</div>
                    </div>
                </div>

            </div>

            <div class="form-group">
                <label for="oktype" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Ok typ' | translate}}</label>
                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="type" name="type" ngModel
                            #type="ngModel">
                            <option *ngFor="let type of listTypes" [value]="type">{{type}}</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="type.touched && !type.valid">
                        <div *ngIf="type.errors.required">{{ 'Du måste välja oktyp' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="Löpnummer" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Löpnummer' |
                    translate}}</label>
                <div class="col-lg-2">
                    <input required id="yokeNumber" ngModel name="yokeNumber" type="text" pattern="[0-9]*"
                        #yokeNumber="ngModel" class="form-control" placeholder="">
                    <div class="alert alert-danger" *ngIf="yokeNumber.touched && !yokeNumber.valid">
                        <div *ngIf="yokeNumber.errors.required">{{ 'Du måste skriva in löpnummer' | translate}}</div>
                        <div *ngIf="yokeNumber.errors.pattern">{{ 'Endast nummer !' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="Löpnummer" class="col-lg-offset-4 col-lg-4 control-label">{{ 'Skrotorsak' |
                    translate}}</label>


                <div class="col-lg-2">
                    <div class="btn-group">
                        <select required class="btn btn-default dropdown-toggle" id="skrotorsak" name="skrotorsak"
                            ngModel #skrotorsak="ngModel">
                            <option *ngFor="let skrot of listSkrotorsak" [value]="skrot.skrotId">
                                {{skrot.skrotId +' '+skrot.skroterror}}</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" *ngIf="skrotorsak.touched && !skrotorsak.valid">
                        <div *ngIf="skrotorsak.errors.required">{{ 'Du måste välja Skrotorsak' | translate}}</div>
                    </div>
                </div>
            </div>


            <div class="form-group">
                <div class="col-lg-offset-5 col-lg-3">
                    <button class="btn btn-success" type="submit" [class.disabled]="!scrappingForm.valid"
                        id="submitBtn">{{ 'Registrera' | translate}}</button>

                </div>

                <div class="col-lg-2">
                    <button class="btn btn-success" type="button" style="background-color: #033a6a;
                     border-color: #033a6a;margin-left: 0px;" (click)="resetForm(scrappingForm)">{{'Återställa' |
                        translate }}</button>
                </div>
            </div>

            <!-- <div class="form-group">
        <div class="col-lg-offset-8 col-lg-3">
                <button class="btn btn-success" (click)="submit(loopRegistrationForm.value)" type="submit" 
                [class.disabled]="!loopRegistrationForm.valid" id="submitBtn">{{ 'Registrera' | translate}}</button>
            <br>
            <br>
            
                <button class="btn btn-success" type="button" (click)="resetForm(loopRegistrationForm)">{{'Återställa' | translate}}</button>
           
        </div>
    </div> -->

            <span [hidden]="true" #getscraperror>{{'Fel vid skrotningstyp' | translate}}</span>
            <span [hidden]="true" #invidnotregistered>{{'Ok individ är ej Registrerad eller fel inmatat' |
                translate}}</span>
            <span [hidden]="true" #updateyokeregistererror>{{'fel i Uppdatering av okregistret' | translate}}</span>

            <span [hidden]="true" #yokeregupdated>{{'Åkregisteret uppdaterat' | translate}}</span>
            <span [hidden]="true" #addyokeinspectionerror>{{'fel i att lägga till Yoke Inspection' | translate}}</span>
            <span [hidden]="true" #addedyokeinspection>{{'Yoke-inspektion tillagd' | translate}}</span>
            <span [hidden]="true" #class>{{'Klass' | translate}}</span>
            <span [hidden]="true" #yoketype>{{'Ok typ' | translate}}</span>
            <span [hidden]="true" #loopnumber>{{'Löpnummer' | translate}}</span>
            <span [hidden]="true" #hasscrapped>{{'har Skrotats' | translate}}</span>
            <span [hidden]="true" #alreadyscrapped>{{'är redan skrotad' | translate}}</span>


            <div class="text-right">
                <div class="form-group">
                    <p id="showmessage"></p>

                    <script>
                        function myFunction() {
                            document.getElementById("showmessage").innerHTML = "Klass CE OK typ 2 löpnummer 44 registrerad. Totalt antal loopar 3";
                            document.getElementById("showmessage").style.color = "green";
                            document.getElementById("Löpnummer").style.backgroundColor = "lightgreen";
                            document.getElementById("okType").style.backgroundColor = "lightgreen";
                            document.getElementById("Klass").style.backgroundColor = "lightgreen";
                        }
                    </script>
                </div>
            </div>
        </form>
    </div>
</div>