
       
            
        <span [hidden]="true" #slang>{{'Användare Tillagt/Uppdaterad Lyckades' | translate}}</span>
        
        <span [hidden]="true" #elang>{{'Användarnamn Redan Finns' | translate}}</span>

        
<div class="container">
    <div id="wrapper">
        <button class="btn btn-success" (click)="showAddUserForm(UserModal)"><i class="fa fa-plus"></i>
            {{'Lägg till Användare' | translate}}</button>
    </div>
    <br />
    <br />
    <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-bordered table-striped table-hover">
            <thead>
                <tr>
                    <th class="text-center">{{'Användare' | translate}} ID</th>
                    <th class="text-center">{{'Rättigheter' | translate}}</th>
                    <th class="text-center" name="edit">{{'Redigera' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users ">
                    <td>{{ user.iDright }}</td>
                    <td>{{ user.rights }}</td>
                    <td class="text-right" style="width: 85px;">
                        <button class="btn btn-info mr-2" (click)="showEditUserForm(UserModal, user)">
                            <i class="fa fa-edit"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>




    </div>
</div>

<ng-template #UserModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'Lägg till / uppdatera Rättigheter' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>


    <form class="form-horizontal" #addUserForm="ngForm" (ngSubmit)="addUser(addUserForm.value)" role="form">
        <div class="modal-body">
            <div class="container">


                <div class="form-group">

                    <input type="hidden" name="id" ngModel #id="ngModel" [(ngModel)]="newUser.id">

                    <label for="userlbl" class="col-md-3 col-form-label">{{'Användare' | translate}} ID</label>
                    <div col-md-1>
                        <input [readonly]="isReadOnly" type="text" name="iDright" ngModel #iDright="ngModel"
                            [(ngModel)]="newUser.iDright" pattern="^[a-zA-Z0-9]{6,10}$" required />
                        <div class="alert alert-danger" *ngIf="iDright.touched && !iDright.valid">
                            <div *ngIf="iDright.errors.pattern">{{'Ogiltiga tecken !' | translate}}</div>
                            <div *ngIf="iDright.errors.required">{{'Inmatningsfältet får inte vara tomt.' | translate}}
                            </div>
                        </div>
                    </div>

                    <br />

                    <label for="rightslbl" class="col-md-3 ">{{'Rättigheter' | translate}}</label>
                    <div col-md-1>
                        <select required id="rights" ngModel name="rights" #rights="ngModel"
                            [(ngModel)]="newUser.rights" style="width: 182px;height:28px; font-size: 14px; ">
                            <option *ngFor="let user of userTypes" [ngValue]="user">{{user}}</option>
                        </select>
                        <div class="alert alert-danger" *ngIf="rights.touched && !rights.valid">
                            <div *ngIf="rights.errors.required">{{'Inmatningsfältet får inte vara tomt.' | translate}}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success" type="submit"
                    [class.disabled]="!addUserForm.valid">{{'Spara' | translate}}</button>
                <button type="submit" class="btn btn-primary" style="background-color: #033a6a;border-color: #033a6a;"
                    (click)="modal.close('Save click')">{{'Avbryt' | translate}}</button>
            </div>

        </div>

    </form>

</ng-template>