import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/data.service';
import {  LoopRegistrationBE,  YokeInspection } from 'src/app/models/loop-registration-model';
import { YokeRegister, Supplier } from 'src/app/models/register-okyoke-model';
import { DecolorizationService } from '../register-decolorization/register-decolorization.service';
import { DatePipe, formatDate } from '@angular/common';
import { RegisterOkYokeService } from './register-okyoke.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-register-okyoke',
  templateUrl: './register-okyoke.component.html',
  styleUrls: ['./register-okyoke.component.css'],
  providers: [DatePipe, RegisterOkYokeService]
})
export class RegisterOkyokeComponent implements OnInit {

  users$: Object;

  @ViewChild('getyokeerror',{static:false}) getYokeErrorRef: ElementRef;
  @ViewChild('addyokeregistererror',{static:false}) addYokeRegisterErrorRef: ElementRef;
  @ViewChild('addyokeinspectionerror',{static:false}) addYokeInspectionErrorRef: ElementRef;
  @ViewChild('yoketype',{static:false}) yokeTypeRef: ElementRef;
  @ViewChild('loopnumber',{static:false}) loopNumberRef: ElementRef;
  @ViewChild('supplier',{static:false}) supplierRef: ElementRef;
  @ViewChild('scrapnoregistration',{static:false}) scrapNoRegistrationRef: ElementRef;
  @ViewChild('alreadyregistered',{static:false}) alreadyRegisteredRef: ElementRef;
  
  constructor(private data: DataService,
    private datePipe: DatePipe,
    private registerOkYokeService: RegisterOkYokeService,
    private decolorizationService: DecolorizationService,
    private toastr: ToastrService) { }

  minDate: Date;
  reg: LoopRegistrationBE;
  regYoke: YokeRegister;
  yokeinspection: YokeInspection = undefined;
  antalreg: number;
  fromNo: number;
  toNo: number;
  supplierNumber: number;
  lblmsgn: string;
  yokeNumber: number;
  totalCounter: number;
  txtnum: number = 100000;
  tomstring: string = null;
  statusVariabel: number;
  type: number;
  skrotad: number;
  currentDate: Date;
  date: any;
  listLeverantor: any;
  listTypes: any;


  ngOnInit() {
    this.data.getOkTest().subscribe(
      data => this.users$ = data
    )

    this.resetForm();
    this.getSupplierList()
    this.getYokeTypesList();

  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();

    this.regYoke = {
      class: null,
      type: null,
      yokeNumber: null,
      supplier: null,
      regDate: null,
      CounterDate: null,
      inspectionCounter: null,
      totalCounter: null,
      skrotad: null
    }
  }

  getSupplierList() {

    this.registerOkYokeService.getSupplierList().subscribe
      (response => {
       // console.log(response.body);
        this.listLeverantor = response.body;
      });
  }

  getYokeTypesList() {
    this.decolorizationService.getYokeTypes()
      .subscribe((result) => {
        this.listTypes = result;
      },
        error => { //This is error part
         // console.log(error.message);          
        },
        () => {
         // console.log(this.listTypes);
        }
      )
  }
  registerIndividFormSubmit(form) {
   // console.log(form);
    this.fromNo = parseInt(form.startdatum);
    if(form.slutdatum != null && form.slutdatum != '')
    this.toNo = parseInt(form.slutdatum);
    else
    this.toNo = null;
    console.log(this.fromNo, this.toNo);

    if ((this.fromNo != null && this.toNo != null) && (this.toNo) < (this.fromNo)) {
      this.toastr.error('To number should be greater than From number.');
      return false;
    }

    this.supplierNumber = parseInt(form.leverantor);
    this.type = parseInt(form.type)
    this.currentDate = new Date();
    this.date = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');


    if (this.toNo == null) {
      this.antalreg = 0;
    } else {
      this.antalreg = this.toNo - this.fromNo;
    }
    this.yokeNumber = this.fromNo;


    for (let index = 0; index <= this.antalreg; index++) {
      this.totalCounter = 0;

      const promise = new Promise((resolve, reject) => {
        this.registerOkYokeService.getYokeNumber(this.type, this.yokeNumber)
          .toPromise()
          .then((response: any) => {
            this.yokeNumberResponse(response.body)
            resolve();
          },
            err => {
              console.log(err.message);
              console.log(err);
              if(err.status == 404)
              this.addYoke();
             // this.lblmsgn = "error in getting YokeNumber";
             // this.toastr.error(this.lblmsgn, 'Error');
             //this.toastr.error(this.getYokeErrorRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
              //return false;
              //reject(err);
            }
          );
      });
      return promise;

    }
  }

  yokeNumberResponse(yoke: YokeRegister) {
    this.txtnum = yoke.yokeNumber
    this.yokeNumber = yoke.yokeNumber
    this.skrotad = yoke.skrotad

    if (this.skrotad == 4) {
      this.lblmsgn =this.yokeTypeRef.nativeElement.innerHTML + " " + this.type + " " + this.loopNumberRef.nativeElement.innerHTML + " " + this.fromNo + " " + this.scrapNoRegistrationRef.nativeElement.innerHTML;
      this.toastr.error(this.lblmsgn, 'Error', {enableHtml:false});
      //this.showBtn = false;
      return false;

    }

    if (this.txtnum != 100000) {
      this.lblmsgn = this.yokeTypeRef.nativeElement.innerHTML + " " + this.type + " " + this.loopNumberRef.nativeElement.innerHTML + " " + this.yokeNumber + " " + this.alreadyRegisteredRef.nativeElement.innerHTML;
      this.toastr.error(this.lblmsgn, 'Error', {enableHtml:false});
      //this.showBtn = false;
      return false;

    }
    this.yokeNumber = this.yokeNumber + 1
  }
  addYoke(){
    
    this.yokeNumber = this.fromNo;

    for (let index = 0; index <= this.antalreg; index++) {
      this.totalCounter = 0;
      this.statusVariabel = 2

      this.regYoke = {
        class: 2,
        type: this.type,
        yokeNumber: this.yokeNumber,
        supplier: this.supplierNumber,
        regDate: this.date,
        CounterDate: this.date,
        inspectionCounter: 0,
        totalCounter: this.totalCounter,
        skrotad: this.statusVariabel
      }

      this.registerOkYokeService.addYokeRegister(this.regYoke)
        .subscribe(resp => {
         // console.log(resp);
          //console.log('YokeRegisterAdd');
        }, err => {
         // this.lblmsgn = "error in Adding Yoke Register";
        //  this.toastr.error(this.lblmsgn, 'Error');
        this.toastr.error(this.addYokeRegisterErrorRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
          return false;
        });

      this.yokeinspection = {
        id: this.yokeNumber,
        class: 2,
        type: this.type,
        yokeNumber: this.yokeNumber,
        status: this.statusVariabel,
        errorTypeId: 0,
        yokeComment: 0,
        inspectDate: this.date,
        inspectionCounter: 0,
        totalcounter: this.totalCounter
      };

      this.registerOkYokeService.addYokeInspection(this.yokeinspection)
        .subscribe(resp => {
         // console.log(resp);
          //console.log('inspection added');
        }, err => {
         // this.lblmsgn = "error in Adding Yoke Inspection";
         // this.toastr.error(this.lblmsgn, 'Error');
         this.toastr.error(this.addYokeInspectionErrorRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
          return false;
        });

      let suppliername = this.listLeverantor.find(x => x.supplierId == this.supplierNumber).suppliername;

      if (this.toNo == null) {

        this.lblmsgn = "Följade är reg:  OK-typ " + this.type + " Fr.o.m " + this.fromNo + " T.o.m " + this.fromNo +  " " +  this.supplierRef.nativeElement.innerHTML +  " " + suppliername + " Totalcounter " + this.totalCounter + " RegDatum " + this.date + "";
        this.toastr.success(this.lblmsgn, 'Success');        
        return false;

      } else {
        this.lblmsgn = "Följade är reg:  OK-typ  " + this.type + " Fr.o.m " + this.fromNo + " T.o.m " + this.toNo +  " " +  this.supplierRef.nativeElement.innerHTML + " " + suppliername + " RegDatum " + this.date + "";
        this.toastr.success(this.lblmsgn, 'Success');
        //this.showBtn = false;
        return false;

      }

      this.yokeNumber = this.yokeNumber + 1;
    }
  }

}
