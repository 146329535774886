import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ReportDate, ScrapInformation } from 'src/app/models/reports-model';
import { AdminUser } from 'src/app/models/admin-model';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ScrapInformationService } from './scrap-information.service';
import { Subject, from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-scrap-information',
  templateUrl: './scrap-information.component.html',
  styleUrls: ['./scrap-information.component.css'],
  providers: [DatePipe, ScrapInformationService]
})
export class ScrapInformationComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: any = {};
  scrapinfolist: ScrapInformation[] = [];
  dtTrigger: Subject<any> = new Subject<any>();


  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;
  
  constructor(private scrapInformationService: ScrapInformationService, private datePipe: DatePipe, private toastr: ToastrService) { }
  reportDate: ReportDate;
  startDate: string;
  endDate: string;
  isValidDate: any;
  
  ngOnInit() : void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'print',
        'excel',
        'csv'
      ]
    };
    this.resetForm(); 
   }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.reportDate = {
      startdatum: '',
      slutdatum: ''
    }
  }

  minDate: Date;
  onValueChange(value: Date): void {
    this.minDate = value;
    //console.log(this.minDate);
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  showScrapInfoSubmit(form) {
    this.startDate = this.transformDate(form.startdatum);
    this.endDate = this.transformDate(form.slutdatum);
    
    console.log(this.startDate, this.endDate);
    if ((this.startDate != null && this.endDate != null) && (this.endDate) < (this.startDate)) {
      this.toastr.error('End date should be greater than start date.');
      this.scrapinfolist = [];
      this.rerender();
      return false;
    }

    this.scrapInformationService.getScrapInfoList(this.startDate, this.endDate)
      .subscribe((result) => {
        this.scrapinfolist = [];
        this.scrapinfolist = result;
        this.rerender();
      },
        error => { //This is error part
          console.log(error.message);
          this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
          //this.toastr.error('poster hittades inte', 'Error');
        },
        () => {
          console.log(this.scrapinfolist);
          if(this.scrapinfolist.length === 0)
          this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
          //this.toastr.error('poster hittades inte', 'Error');
        }
      )
  }

    
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}