import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ReportsReg } from 'src/app/models/reports-model';
import { NgForm } from '@angular/forms';
import { LoopInformation } from 'src/app/models/reports-model';
import { LoopInformationService } from './loop-information.service';
import { DecolorizationService } from 'src/app/register/register-decolorization/register-decolorization.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loop-information',
  templateUrl: './loop-information.component.html',
  styleUrls: ['./loop-information.component.css'],
  providers: [LoopInformationService]
})
export class LoopInformationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  
  @ViewChild('elang',{static:false}) eDivElementRef: ElementRef;

  constructor(private loopInformationService: LoopInformationService, private decolorizationService: DecolorizationService,
    private toastr: ToastrService) { }

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  listTypes: any;
  loopReg : ReportsReg;
  loopinfo: LoopInformation[] = [];
  lblmsgn : string;
  selectedValue="2";

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'print',
        'excel',
        'csv'
      ]
    };
    this.resetForm();
    this.getYokeTypes();
  }

  getYokeTypes() {
    this.decolorizationService.getYokeTypes()
      .subscribe((result) => {
        this.listTypes = result;
      },
        error => { //This is error part
         // console.log(error.message);
          //this.toastrService.error('error', 'Something went wrong while getting the customers, Please try after sometime.');
        },
        () => {
         // console.log(this.listTypes);
        }
      )
  } 

  resetForm(form?:NgForm)
  {
    if(form != null)
    form.reset();
    this.loopReg = {
      class: null,
      type: null,
      yokeNumber: null
    }
    
  }

  showLoopInfoSubmit(form){
   // console.log(form);
    this.loopReg.class = parseInt(form.class);
    this.loopReg.type = parseInt(form.type);
    this.loopReg.yokeNumber = parseInt(form.yokeNumber);

    this.loopInformationService.GetLoopInfo( this.loopReg.class, this.loopReg.type,this.loopReg.yokeNumber)
    .subscribe((result) => {
      this.loopinfo = [];
      this.loopinfo = result;
      this.rerender();
    },
      error => { //This is error part
        console.log(error.message);
        this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
        //this.toastr.error('poster hittades inte', 'Error');
      },
      () => {
       // console.log(this.loopinfo);
        if (this.loopinfo.length === 0)
        this.toastr.error(this.eDivElementRef.nativeElement.innerHTML, '', {enableHtml: false});
         // this.toastr.error('poster hittades inte', 'Error');
      }
    )
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
