<div class="col-md-10 offset-md-1">
    <div>
        <h2 style="font-family: Scania Sans Bold, Helvetica, Arial, Sans-serif;">{{'bruksanvisning'| translate}}</h2>
    </div>
    <br>
    <table class="table table-bordered table-striped table-hover">
        <thead>
            <tr>
                <th class="text-center">{{'Filnamn' | translate}}</th>
                <th class="text-center">{{'Se' | translate}}</th>
                <th class="text-center">{{'Spara' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of pdfFiles">
                <td scope="row">{{ item.item1 }}</td>
                <td>
                    <button type="button" class="btn btn-primary" (click)="viewFile(item.item1, item.item3)">
                        {{'Se' | translate}}
                    </button>
                </td>
                <td>
                    <button type="button" class="btn btn-success " (click)="download(item.item1, item.item3)">
                        {{'Spara' | translate}}
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>