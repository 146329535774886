import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from '../dashboard/dashboard.component';
import { RegisterComponent } from '../register/register.component';
import { ReportsComponent } from '../reports/reports.component';
import { AdministrationComponent } from '../administration/administration.component';
import { InstructionComponent } from '../instruction/instruction.component';
import { LoopRegistrationComponent } from '../register/loop-registration/loop-registration.component';
import { RegisterDecolorizationComponent } from '../register/register-decolorization/register-decolorization.component';
import { RegisterScrappingComponent } from '../register/register-scrapping/register-scrapping.component';
import { RegisterAdjustmentComponent } from '../register/register-adjustment/register-adjustment.component';
import { RegisterOkyokeComponent } from '../register/register-okyoke/register-okyoke.component';
import { LoopInformationComponent } from '../reports/loop-information/loop-information.component';
import { ScrapInformationComponent } from '../reports/scrap-information/scrap-information.component';
import { EventInformationComponent } from '../reports/event-information/event-information.component';
import { SumInformationComponent } from '../reports/sum-information/sum-information.component';
import { TotalInformationComponent } from '../reports/total-information/total-information.component';
import { ShowOktypeComponent } from '../administration/show-oktype/show-oktype.component';
import { ShowSupplierComponent } from '../administration/show-supplier/show-supplier.component';
import { ShowUserComponent } from '../administration/show-user/show-user.component';
import { ShowAdjustmentComponent } from '../administration/show-adjustment/show-adjustment.component';
import { ShowScrapComponent } from '../administration/show-scrap/show-scrap.component';
import { ShowInstructionComponent } from '../administration/show-instruction/show-instruction.component';
import { AuthGuardService } from '../authentication/authguard.service';
import { UserService } from '../authentication/user.service';
//import { IsUserService } from './authentication/IsUserService';
import { IsUserService } from '../authentication/is-user.service'
import { AuthService } from '../authentication/authentication.service';


const routes: Routes = [
 
  {path: 'dashboard',    component: DashboardComponent  },
  
  {path: 'administration', component : AdministrationComponent,
  children: [{path: 'showoktype', component : ShowOktypeComponent,canActivate:[AuthGuardService]},
             {path: 'showsupplier', component: ShowSupplierComponent,canActivate:[AuthGuardService]},
             {path: 'showuser', component: ShowUserComponent,canActivate:[AuthGuardService]},
             {path: 'showadjustment', component: ShowAdjustmentComponent,canActivate:[AuthGuardService]},
             {path: 'showscrap', component: ShowScrapComponent,canActivate:[AuthGuardService]},
             {path: 'showinstruction', component: ShowInstructionComponent,canActivate:[AuthGuardService]}],canActivate:[AuthGuardService]
},
  {path: 'instruction',    component: InstructionComponent,canActivate:[IsUserService]  },

  {path: 'register',    component: RegisterComponent,
  children: [{path: 'loopregistration', component : LoopRegistrationComponent,canActivate:[IsUserService]},
             {path: 'decolorization', component: RegisterDecolorizationComponent,canActivate:[IsUserService]},
             {path: 'adjustment', component: RegisterAdjustmentComponent,canActivate:[IsUserService]},
             {path: 'scrapping', component: RegisterScrappingComponent,canActivate:[IsUserService]},
             {path: 'okyoke', component: RegisterOkyokeComponent,canActivate:[IsUserService]}] ,canActivate:[IsUserService] 
  },

  {path: 'reports', component : ReportsComponent,
  children:  [{path: 'loopinformation', component : LoopInformationComponent,canActivate:[IsUserService]},
              {path: 'eventinformation', component: EventInformationComponent,canActivate:[IsUserService]},
              {path: 'scrapinformation', component: ScrapInformationComponent,canActivate:[IsUserService]},
              {path: 'suminformation', component: SumInformationComponent,canActivate:[IsUserService]},
              {path: 'totalinformation', component: TotalInformationComponent,canActivate:[IsUserService]}] ,canActivate:[IsUserService]
},  

  {path: '**' , redirectTo : 'dashboard'},

];

@NgModule({providers: [ AuthGuardService, UserService,IsUserService,AuthService ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: [] 
})
export class AppRoutingModule { }
