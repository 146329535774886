import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent {

  LoopReg : boolean = false ;
  Decolor : boolean = false ;
  Adjust : boolean = false ;
  ScrapVar : boolean = false;
  YokeVar : boolean = false ;
  constructor(private route: ActivatedRoute, private router: Router) { }
  

  loopregistration()  {
  
    this.router.navigate(['loopregistration'], { relativeTo: this.route });
    this.LoopReg = true;
    this.Decolor = false;
    this.Adjust = false;
    this.ScrapVar = false;
    this.YokeVar = false ;
  }

  decolorization()  {
    this.router.navigate(['decolorization'], { relativeTo: this.route });
    this.LoopReg = false;
    this.Decolor = true;
    this.Adjust = false;
    this.ScrapVar = false;
    this.YokeVar = false ;
  }

  adjustment() {
    this.router.navigate(['adjustment'], { relativeTo: this.route });
   
    this.LoopReg = false;
    this.Decolor = false;
    this.Adjust = true;
    this.ScrapVar = false;
    this.YokeVar = false ;
  }

  scrap() {
    this.router.navigate(['scrapping'], { relativeTo: this.route });
   
    this.LoopReg = false;
    this.Decolor = false;
    this.Adjust = false;
    this.ScrapVar = true;
    this.YokeVar = false ;
  }

  yoke() {
    this.router.navigate(['okyoke'], { relativeTo: this.route });
    
    this.LoopReg = false;
    this.Decolor = false;
    this.Adjust = false;
    this.ScrapVar = false;
    this.YokeVar = true ;
  }

}
