import { Injectable, ElementRef, ViewChild } from '@angular/core';
import { CanLoad, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { UserService } from 'src/app/authentication/user.service'
import { AdminUser } from '../models/admin-model';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';

export interface User {
    name: string;
    adminUser: string
}

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

     private user: AdminUser = {
    name: '',
    adminUser: ''
};



    constructor(private router: Router,
        private userService: UserService,
        private _authservice: AuthService,
        private toastr: ToastrService) {
    }


    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
        this.user = this.userService.getUser();      
      
        if (this.user && this.user.name && this.user.adminUser) {           
            if (this.user.adminUser.toUpperCase() == 'ADMIN') { 
                localStorage.removeItem('state_url');
                localStorage.setItem('state_url', state.url);            
                return true;
            }
            else {
            this.toastr.error('Du har inte behörighet till administratörssidan');

                return this.router.parseUrl("/dashboard");
            }
        } else {
            this.toastr.error('Du är inte auktoriserad');
            localStorage.clear();
            this._authservice.userAuthenticationAPI();
        }

    }
}