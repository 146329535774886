<span [hidden]="true" #slang>{{'Ok Skrot Tillagt/Uppdaterad Lyckades' | translate}}</span>
        
        <span [hidden]="true" #elang>{{'Ok Skrot Redan Finns' | translate}}</span>


<div class="container">
    <div id="wrapper">
        <button class="btn btn-success" (click)="AddNewScrapForm(scrapModal)"><i class="fa fa-plus"></i>
            {{ 'Lägg till Skrotorsak' | translate}}</button>
    </div>
    <br />
    <br />
    <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-bordered table-striped table-hover">
            <thead>
                <tr>
                    <th class="text-center">{{ 'Skrotorsak' | translate}}</th>
                    <th class="text-center" name="edit">{{'Redigera' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let scrap of yokeSkrot">
                    <td>{{ scrap.skroterror }}</td>
                    <td class="text-right" style="width: 85px;">
                        <button class="btn btn-info mr-2" (click)="EditScrapForm(scrapModal, scrap)">
                            <i class="fa fa-edit"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <ng-template #scrapModal let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">{{ 'Lägg till / uppdatera Skrotorsak' | translate}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <form class="form-horizontal" #addScrapForm="ngForm" (ngSubmit)="addScrap(addScrapForm.value)" role="form">
                <div class="modal-body">
                    <div class="container">

                        <input type="hidden" name="skrotId" ngModel #errorTypeId="ngModel"
                            [(ngModel)]="newScrap.skrotId" />
                        <div class="form-group">
                            <label for="errortypelbl"
                                class="col-sm-4 col-form-label">{{ 'Skrotorsak' | translate}}</label>
                            <div col-md-5>
                                <input type="text" name="skroterror" ngModel #skroterror="ngModel"
                                    [(ngModel)]="newScrap.skroterror"
                                    pattern="^[a-zA-Z0-9ÅåÄäÖö]+(\s+[A-Za-z0-9ÅåÄäÖö\(\)]+)*$" required />
                                <div class="alert alert-danger" *ngIf="skroterror.touched && !skroterror.valid">
                                    <div *ngIf="skroterror.errors.required">
                                        {{ 'Inmatningsfältet får inte vara tomt.' | translate}}</div>
                                    <div *ngIf="skroterror.errors.pattern">{{ 'Ogiltiga tecken !' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-success" type="submit" [class.disabled]="!addScrapForm.valid"
                        id="hämtadatabtn4">{{'Spara' | translate}}</button>
                    <button type="button" class="btn btn-primary" style="background-color: #033a6a;
                    border-color: #033a6a;" (click)="modal.close('Save click')">{{'Avbryt' | translate}}</button>
                </div>

            </form>

        </ng-template>


    </div>
</div>