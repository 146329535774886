<span [hidden]="true" #slang>{{'Leverantör Tillagt/Uppdaterad Lyckades' | translate}}</span>
        
        <span [hidden]="true" #elang>{{'Leverantör Redan Finns' | translate}}</span>


<div class="container">
    <div id="wrapper">
        <button class="btn btn-success" (click)="AddSupplierForm(SupplierModal)"><i class="fa fa-plus"></i>
            {{'Lägg till Leverantör' | translate}}</button>
    </div>
    <br />
    <br />

    <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-bordered table-striped table-hover" width=100%>
            <thead>
                <tr>
                    <!-- <th class="text-center">{{'Leverantörs' | translate}} ID</th> -->
                    <th class="text-center">{{'Leverantör' | translate}}</th>
                    <th class="text-center">{{'Aktiv' | translate}}</th>
                    <th class="text-center" name="edit">{{'Redigera' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let supplier of suppliers">
                    <!-- <td>{{ supplier.supplierId}}</td> -->
                    <td>{{ supplier.suppliername }}</td>
                    <td>{{ supplier.aktiv }}</td>
                    <td class="text-right" style="width: 85px;">
                        <button class="btn btn-info mr-2" (click)="EditSupplierForm(SupplierModal, supplier)">
                            <i class="fa fa-edit"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>




    </div>
</div>

<ng-template #SupplierModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'Redigera Leverantör Typ' | translate}}</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form class="form-horizontal" #addSupplierTypeForm="ngForm" (ngSubmit)="addSupplierType(addSupplierTypeForm.value)"
        role="form">
        <div class="modal-body">

            <div class="container">
                <div class="form-group ">

                    <input type="hidden" name="supplierId" ngModel #supplierId="ngModel"
                        [(ngModel)]="newSupplier.supplierId">


                    <label for="supplierName" class="col-sm-4 col-form-label">{{'Leverantör' | translate}}</label>
                    <div col-md-1>
                        <input type="text" name="suppliername" ngModel #suppliername="ngModel"
                            pattern="^[a-zA-Z0-9ÅåÄäÖö]+(\s+[A-Za-z0-9ÅåÄäÖö\(\)]+)*$"
                            [(ngModel)]="newSupplier.suppliername" required />
                        <div class="alert alert-danger" *ngIf="suppliername.touched && !suppliername.valid">
                            <div *ngIf="suppliername.errors.required">
                                {{'Inmatningsfältet får inte vara tomt.' | translate}}</div>
                            <div *ngIf="suppliername.errors.pattern">{{'Ogiltiga tecken !' | translate}}</div>

                        </div>
                    </div>
                    <br />


                    <label for="active" class="col-sm-4 ">{{'Aktiv' | translate}}</label>
                    <div col-md-1>
                        <select type="text" name="aktiv" ngModel #aktiv="ngModel" [(ngModel)]="newSupplier.aktiv"
                            required style="width: 182px;height:28px;">
                            <option [value]="true">{{'Sant' | translate}}</option>
                            <option [value]="false">{{'Falska' | translate}}</option>
                        </select>
                        <div class="alert alert-danger" *ngIf="aktiv.touched && !aktiv.valid">
                            <div *ngIf="aktiv.errors.required">{{'Inmatningsfältet får inte vara tomt.' | translate}}
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-success" type="submit" [class.disabled]="!addSupplierTypeForm.valid"
                    id="hämtadatabtn4">{{'Spara' | translate}}</button>
                <button type="button" class="btn btn-primary" style="background-color: #033a6a;
                border-color: #033a6a;" (click)="modal.close('Save click')">{{'Avbryt' | translate}}</button>


            </div>
        </div>
    </form>

</ng-template>