import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from "rxjs"
import { map, catchError } from 'rxjs/operators';
import { EventInformation } from 'src/app/models/reports-model';
import { AppConfig } from '../../config/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EventInformationService {

  constructor(private config: AppConfig, private http: HttpClient) { }
  private apiUrl: string = this.config.get('apiUrl') + 'Reports';

  eventInformation: EventInformation[];


  getEventInfo(Class: number, type: number, yokeNumber: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetEventInfo?Class=${Class}&type=${type}&yokeNumber=${yokeNumber}`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }
  errorHandler(error: Response) {
    // console.log(error);
    return throwError(error);
  }

}