import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  
} from '@angular/common/http';
import { UserService } from './user.service';
import { Observable } from 'rxjs/Observable';
import { AppConfig } from '../config/app.config';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: UserService, private config: AppConfig) {}

  private apiUrl: string = this.config.get('apiUrl');


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getAuthToken()}`
      }
    });
    
    //return next.handle(request);
    return next.handle(request).pipe( tap(() => {},
    (err: any) => {
    if (err instanceof HttpErrorResponse) {
      if (err.status !== 401) {
       return;
      }
      this.openDialog();
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');
      location.reload();
      window.location.replace(`${this.apiUrl}UserAuthentication/SSO`);
    }
  }));
    
  }
  openDialog() {
    alert("Your application session is expired. You will be logged-in again and redirected to home page.");
  }
}