import { Injectable } from '@angular/core';
import { SAMLUserModel } from 'src/app/models/saml-user-model';



export interface User {
    name: string;
    adminUser: string;
    userId: string,
    emailId: string
}

@Injectable()
export class UserService {



    constructor() { }

    private auth_token = '';
    private user: User = {
        name: '',
        adminUser: '',
        userId: '',
        emailId: ''

    };

    public sUsers: SAMLUserModel;

    // You can use this operator if you know that the response will always return something than null 
    //this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    getAuthToken(): string {
        if (localStorage.getItem('auth_token')) {
            //localStorage.removeItem('auth_token');
            //localStorage.removeItem('user');
            // console.log("now we are in the Auth Token of user service");
            this.user = JSON.parse(localStorage.getItem('user')!);
            //this.user = JSON.parse(localStorage.getItem('user')!);            
            //console.log(localStorage.getItem('auth_token'));
            // console.log(localStorage.getItem('user'));
            return localStorage.getItem('auth_token')!;

        } else {
            return this.auth_token;
        }
    }
    setAuthToken(auth_token: string): void {
        this.auth_token = auth_token;
        localStorage.setItem('auth_token', auth_token);
        // console.log("now we are in the Set AUTH token function!")

    }
    setUser(name: string, adminUser: string, userId: string, emailId: string) {

        this.user = { name, adminUser, userId, emailId };
        localStorage.setItem('user', JSON.stringify({ name, adminUser, userId, emailId }));
        // console.log("now we are in the Set User  function!")

    }
    getUser(): User {
        //this.user = JSON.parse(localStorage.getItem('user')!);

        //  console.log("loaded user" + this.user);
        //  console.log("now we are in the Get user function!")
        //  console.log(localStorage.getItem('user'));
        this.user = JSON.parse(localStorage.getItem('user')!);
        return this.user;
    }

  


}
