import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent //implements OnInit
{

  okType : boolean = false ;
  Supplier : boolean = false ;
  user : boolean = false ;
  Justertyp : boolean = false;
  Skrotorsak : boolean = false ;
  show  : boolean = false  ;
  constructor(private route: ActivatedRoute, private router: Router, public translate: TranslateService) { }

  title: string = null;

  showOktype(): void {
    this.router.navigate(['showoktype'], { relativeTo: this.route });
    this.title = "Ok-typ";
    this.okType = true;
    this.Supplier = false;
    this.user = false;
    this.Justertyp = false;
    this.Skrotorsak = false ;
    this.show = false;
  }

  showsupplier(): void {
    this.router.navigate(['showsupplier'], { relativeTo: this.route });
    this.title = "Leverantör";
    this.Supplier = true;
    this.okType = false;    
    this.user = false;
    this.Justertyp = false;
    this.Skrotorsak = false;
    this.show = false;
  }

  showuser(): void {
    this.router.navigate(['showuser'], { relativeTo: this.route });
    this.title = "Användare";
    this.user = true;
    this.okType = false;
    this.Supplier = false;   
    this.Justertyp = false;
    this.Skrotorsak = false ;
    this.show = false;
  }

  showadjustment(): void {
    this.router.navigate(['showadjustment'], { relativeTo: this.route });
    this.title = "Justertyp";
    this.Justertyp = true;
    this.okType = false;
    this.Supplier = false;
    this.user = false;  
    this.Skrotorsak = false ;
    this.show = false;
  }

  showscrap(): void {
    this.router.navigate(['showscrap'], { relativeTo: this.route });
    this.title = "Skrotorsak";
    this.Skrotorsak = true ;
    this.okType = false;
    this.Supplier = false;
    this.user = false;
    this.Justertyp = false;    
    this.show = false;
  }

  showinstruction(): void {
    this.router.navigate(['showinstruction'], { relativeTo: this.route });
    this.title = "Sökväg instruktion";
    this.show = true;
    this.okType = false;
    this.Supplier = false;
    this.user = false;
    this.Justertyp = false;
    this.Skrotorsak = false ;
   
  }
}
