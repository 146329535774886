import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoopRegistrationBE, YokeInspection } from 'src/app/models/loop-registration-model';
import { NgForm } from '@angular/forms';
import { RegisterScrappingService } from './register-scrapping.service';
import { DecolorizationService } from '../register-decolorization/register-decolorization.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-register-scrapping',
  templateUrl: './register-scrapping.component.html',
  styleUrls: ['./register-scrapping.component.css'],
  providers: [RegisterScrappingService]
})
export class RegisterScrappingComponent implements OnInit {

  @ViewChild('getscraperror',{static:false}) getScrapErrorRef: ElementRef;
  @ViewChild('invidnotregistered',{static:false}) invidNotRegisteredRef: ElementRef;
  @ViewChild('updateyokeregistererror',{static:false}) updateYokeRegisterErrorRef: ElementRef;
 
  @ViewChild('yokeregupdated',{static:false}) yokeRegUpdatedRef: ElementRef;
  @ViewChild('addyokeinspectionerror',{static:false}) addYokeInspectionErrorRef: ElementRef;
  @ViewChild('addedyokeinspection',{static:false}) addedYokeInspectionRef: ElementRef;
  @ViewChild('class',{static:false}) classRef: ElementRef;
  @ViewChild('yoketype',{static:false}) yokeTypeRef: ElementRef;
  @ViewChild('loopnumber',{static:false}) loopNumberRef: ElementRef;
  @ViewChild('hasscrapped',{static:false}) hasScrappedRef: ElementRef;
  @ViewChild('alreadyscrapped',{static:false}) alreadyScrappedRef: ElementRef;
  
  constructor(private registerScrappingService: RegisterScrappingService, private DecolorizationService: DecolorizationService, private toastr: ToastrService) { }

  selectedValue="2";
  registration: LoopRegistrationBE;
  check: boolean;
  check1: boolean;
  skrotDatum: Date;
  lblmsgn: string;
  Klass: string;
  yokeinspection: YokeInspection = undefined;
  date: any;
  currentDate: Date;
  listSkrotorsak: any;
  listTypes: any;
  skrotTypeIdiD: number;


  ngOnInit() {
    this.resetForm();
    this.getYokeScrappingType();
    this.getYokeTypes();
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();

    this.registration = {
      classtype: null,
      type: null,
      yokeNumber: null,
      totalCount: null,
      totalCounter: null,
      skrotad: null,
      interval: null,
      painted: null,
      inspectionCounter: null,
      intStatus: null,
      counterDate: null,
      skrotDatum: null,
      aktiv: null
    }
  }

  getYokeScrappingType() {

    this.registerScrappingService.getYokeScrappingType()
      .subscribe(response => {
        this.listSkrotorsak = response.body;

      }, error => {
       // this.lblmsgn = 'Error in getting Scraapping type'
       // this.toastr.error(this.lblmsgn, 'Error');
       this.toastr.error(this.getScrapErrorRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
        return false;
      }
      )
  }

  getYokeTypes() {
    this.DecolorizationService.getYokeTypes()
      .subscribe((result) => {
        this.listTypes = result;
      },
        error => { //This is error part
         // console.log(error.message);         
        },
        () => {
         // console.log(this.listTypes);
        }
      )
  }

  submit(form) {
   console.log(form);
    this.registration.classtype = parseInt(form.classType);
    // A eller CE
    this.registration.type = parseInt(form.type);          // oktyp
    this.registration.yokeNumber = parseInt(form.yokeNumber);    // oknr
    this.registration.intStatus = 4;             // Skrot
    this.registration.totalCounter = null;
    this.registration.inspectionCounter = null;
    this.skrotTypeIdiD = parseInt(form.skrotorsak)
    this.currentDate = new Date();
    this.date = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');


    this.check = false;
    this.check1 = false;

    const promise = new Promise((resolve, reject) => {
      this.registerScrappingService.getScrappingList(this.registration.classtype, this.registration.type, this.registration.yokeNumber)
        .toPromise()
        .then((response: any) => {
          this.getScrappingListResponse(response.body)
          resolve();
        },
          err => {
          //  this.lblmsgn = 'Ok individ är ej Registrerad eller fel inmatat'
           // this.toastr.error(this.lblmsgn, 'Error');
           this.toastr.error(this.invidNotRegisteredRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
            return false;
            reject(err);
          }
        );
    });
    return promise;

  }


  getScrappingListResponse(regList: LoopRegistrationBE) {

    if (regList != null) {
      if (this.registration.yokeNumber != regList.yokeNumber) {
        this.check = true;

        this.lagrat(); // om vilkoret inte är uppfyllt enligt ovan close myConnection och visa felmedelande.
      }
      else {
        this.check1 = true;
        this.registration.inspectionCounter = regList.inspectionCounter;
        this.registration.totalCounter = regList.totalCounter;
        this.registration.interval = regList.interval;
        this.registration.totalCount = regList.totalCount;
        this.registration.skrotad = regList.skrotad;
        this.registration.painted = regList.painted;
        this.skrotDatum = regList.counterDate;

        this.lagra();
      }
    }
  }
  lagrat() {
   // this.lblmsgn = "Ok individ är ej Registrerat eller fel inmatat";
    //this.toastr.error(this.lblmsgn, 'Error');
    this.toastr.error(this.invidNotRegisteredRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
    return;
  }
  lagra() {
    if (this.registration.skrotad === 4)
      this.skrot();
    else
    {
      this.registration.inspectionCounter = 99;
    let skrotError
    this.listSkrotorsak.forEach(element => {
      if (element.skrotId == this.skrotTypeIdiD) {
        skrotError = element.skrotId;
      }
    });

    this.yokeinspection = {
      id: this.registration.painted,
      class: this.registration.classtype,
      type: this.registration.type,
      yokeNumber: this.registration.yokeNumber,
      status: 4,
      errorTypeId: this.skrotTypeIdiD,
      yokeComment: 0,
      inspectDate: this.date,
      inspectionCounter: this.registration.inspectionCounter,
      totalcounter: this.registration.totalCount
    };
    const promise = new Promise((resolve, reject) => {
      this.registerScrappingService
        .updateYokeRegister(this.registration.inspectionCounter, skrotError, this.registration.totalCounter, this.registration.classtype, this.registration.type, this.registration.yokeNumber)
        .toPromise()
        .then((response: any) => {
          // this.updateYokeRegisterResponse(response.body)
         // console.log('Yoke register Updated');
         // this.lblmsgn = 'Yoke register Updated';
          //this.toastr.success(this.lblmsgn, 'success');
          this.toastr.success(this.yokeRegUpdatedRef.nativeElement.innerHTML, 'Success', {enableHtml:false});
          resolve();
        },
          err => {
         //   this.lblmsgn = "error in Updating yoke register";
         //   this.toastr.error(this.lblmsgn, 'Error');
         this.toastr.error(this.updateYokeRegisterErrorRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
            return false;
            reject(err);
          }
        );
    });

    const promise1 = new Promise((resolve, reject) => {
      this.registerScrappingService.addYokeInspection(this.yokeinspection)
        .toPromise()
        .then((response: any) => {
          // this.addYokeInspectionResponse(response.body);
         // console.log('inspection added');
         // this.lblmsgn = 'inspection added';
        //  this.toastr.success(this.lblmsgn, 'success');
        this.toastr.success(this.addedYokeInspectionRef.nativeElement.innerHTML, 'Success', {enableHtml:false});
          resolve();
        },
          err => {
          //  this.lblmsgn = "error in Adding Yoke Inspection";
           // this.toastr.error(this.lblmsgn, 'error');
           this.toastr.error(this.addYokeInspectionErrorRef.nativeElement.innerHTML, 'Error', {enableHtml:false});
            return false;
            reject(err);
          }
        );
    });

    if (this.registration.classtype === 2)
      this.Klass = "CE";
    if (this.registration.classtype === 1)
      this.Klass = "A";
    // Klass A/CE Ok-typ X Löpnummer Y har Skrotats(Class A / CE Ok type X Serial number Y has been scrapped)
    this.lblmsgn = this.classRef.nativeElement.innerHTML + " " + this.Klass +this.yokeTypeRef.nativeElement.innerHTML + " " + this.registration.type + " " + this.loopNumberRef.nativeElement.innerHTML + " "+ this.registration.yokeNumber + " " + this.hasScrappedRef.nativeElement.innerHTML;
    this.toastr.success(this.lblmsgn, 'success', {enableHtml:false});
    return;
    }
    
  }



  skrot() {
    if (this.registration.classtype === 2)
      this.Klass = "CE";
    if (this.registration.classtype === 1)
      this.Klass = "A";
    let date = formatDate(this.skrotDatum, 'yyyy-MM-dd', 'en-US');
    this.lblmsgn = this.classRef.nativeElement.innerHTML + " " + this.Klass + " " + this.yokeTypeRef.nativeElement.innerHTML + " " + this.registration.type + " " + this.loopNumberRef.nativeElement.innerHTML + " " + this.registration.yokeNumber + this.alreadyScrappedRef.nativeElement.innerHTML + " " + date;
    this.toastr.success(this.lblmsgn, 'Success', {enableHtml:false});
    return;
  }

}
