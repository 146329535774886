export class YokeRegister{
   
    class: number;
    type: number;
    yokeNumber: number;
    supplier: number;
    regDate: Date;
    CounterDate:Date
    inspectionCounter: number;
    totalCounter: number;
    skrotad: number;
}

export class Supplier{
   
   supplierId: number;
   supplierName: string;

   
}

