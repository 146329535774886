import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { YokeInspection } from 'src/app/models/loop-registration-model';
import { YokeRegister } from 'src/app/models/register-okyoke-model';
import { Observable, throwError, pipe } from 'rxjs';
import { Supplier } from 'src/app/models/admin-model';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RegisterOkYokeService {
  constructor(private config: AppConfig, private http: HttpClient) { }
  private apiUrl: string = this.config.get('apiUrl') + 'RegisterOkYoke';

  getYokeTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/GetYokeTypes`)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  getYokeNumber(type: number, yokeNumber: number): Observable<HttpResponse<YokeRegister>> {
    return this.http.get<YokeRegister>(`${this.apiUrl}/GetYokeNumber?type=${type}&yokeNumber=${yokeNumber}`, { observe: 'response' })
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  addYokeInspection(yokeInspection: YokeInspection): Observable<any> {
    return this.http.post(`${this.apiUrl}/AddYokeInspection`, yokeInspection, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  

  addYokeRegister(yokeRegister: YokeRegister): Observable<any> {

    return this.http.post(`${this.apiUrl}/AddYokeRegister`, yokeRegister, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }
  getSupplierList(): Observable<HttpResponse<Supplier>> {
    return this.http.get<Supplier>(`${this.apiUrl}/GetSupplierList`, { observe: 'response' })
      .pipe(
        map(res => res),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: Response) {
    //console.log(error);
    return throwError(error);
  }





}
